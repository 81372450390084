import { ReactElement } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import ContractorsImage from "../../images/contractors-banner.webp";
import ContractorsPriceCard from "pages/international-solutions/components/Cards/ContractorsPriceCard";
import { ContractorsFAQ } from "pages/international-solutions/components/FAQ/ContractorsFAQ";

const Contractors = (): ReactElement => {
  const {
    container,
    column1,
    column2,
    divWrapper,
    mdFont,
    mdGap,
    lgGap,
    lgHead,
    listWrapper,
    xsHead,
    hr,
    infoText,
    price,
    listItems,
  } = styles;

  return (
    <>
      <div className={container}>
        <div className={column1}>
          <img src={ContractorsImage} alt="" />
          <div className={`${divWrapper} ${lgGap}`}>
            <div className={lgHead}>Kickstart your global expansion</div>
            <div className={mdFont}>
              Bring on great talent quickly, and pay them easily, wherever they
              are. Embrace the potential of a global workforce and streamline
              your operations.
            </div>
            <div>
              <ul className={listWrapper}>
                <li>
                  <div className={xsHead}>Consolidated payment tools</div>
                  Save time and money by paying international contractors in
                  over 50 countries from a single platform, hassle-free.
                </li>
                <li>
                  <div className={xsHead}>Build a positive work experience</div>
                  Deliver seamless payment experiences that enhance contractor
                  satisfaction, improving trust and engagement.
                </li>
                <li>
                  <div className={xsHead}>
                    Enhanced expense management with custom reporting
                  </div>
                  Access detailed reporting and analytics to track exchange
                  rates for each payment and generate custom payment reports by
                  date range for improved expense management.
                </li>
              </ul>
            </div>
          </div>
          <hr className={hr}></hr>
          <div className={`${divWrapper} ${mdGap}`}>
            <div className={lgHead}>International Contractor Pricing</div>
            <div>
              <div className={infoText}>
                <span className={price}>$39</span>/month per contractor
              </div>
              <h3>Billing</h3>
              <div className={infoText}>
                At the end of the month, we’ll calculate your total
                international contractor fees and add them under Justworks fees
                in your invoice. You’ll be charged only for the contractors you
                paid during the month.
              </div>
            </div>
          </div>

          <div className={`${divWrapper} ${mdGap}`}>
            <div className={lgHead}>How it works</div>
            <div>
              <div>
                Paying international contractors is very similar to paying
                U.S.–based contractors.
              </div>
              <ul className={listItems}>
                <li>
                  Go to Manage {">"} Contractors to add a new International
                  contractor in the Invitation center.
                </li>
                <li>
                  Fill out all of their details and invite them to Justworks.
                </li>
                <li>
                  When you're ready to pay the new contractor, head to the
                  Payments Center and find International contractor pay.
                </li>
                <li>
                  When you select Send payment, you'll see all of the
                  international contractors that have been added to the team.
                </li>
                <li>
                  Select the contractors you want to pay. You'll see the
                  timeline for the transaction as well as the total amounts.
                  Remember, funds are always debited in USD but delivered to the
                  contractor in their local currency.
                </li>
                <li>Select Submit payments and you're done. </li>
              </ul>
              <div>
                <p>
                  <b>Note:</b> Due to fluctuating exchange rates, international
                  contractor payments process immediately to ensure payment
                  amount is closely aligned with the estimated exchange rate
                  displayed. The Justworks{" "}
                  <b>Contractor Payment Processing Services Agreement</b> -
                  which contains more information on the calculation - can be
                  found in the final review page, just above the{" "}
                  <b>Submit Payment </b>
                  button.
                </p>
              </div>
            </div>
          </div>
          <div className={`${divWrapper} ${mdGap}`}>
            <ContractorsFAQ />
          </div>
        </div>
        <div className={column2}>
          <ContractorsPriceCard />
        </div>
      </div>
    </>
  );
};

export default Contractors;
