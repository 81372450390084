import { Button } from "@justworkshr/milo-core";
import { InputMessage, Label } from "@justworkshr/milo-form";
import { FieldArray, getIn, useFormikContext } from "formik";
import styles from "./AutoFormFieldArray.module.css";
import { ArrayFieldDataType, FieldTypes } from "./types";
import isEqual from "lodash/isEqual";
import range from "lodash/range";
import AutoFormField from "./AutoFormField";

const { row, addMoreButton, hideLabel, removeButton, message } = styles;

type Props = ArrayFieldDataType;

function defaultValue(type: FieldTypes) {
  if (type === FieldTypes.phoneNumber) {
    return { type: "", value: "" };
  }

  return "";
}

export default function AutoFormFieldArray(props: Props) {
  const { type, name, array } = props;
  const addButtonLabel = "Add more";
  const limit = array.limit;
  const getIndexedName = (name: string, index: number) => `${name}.${index}`;

  const { values } = useFormikContext();
  // TODO: Improve typing here.
  const valuesArray: string[] = getIn(values, name);
  const valuesArrayLength = Math.max(valuesArray.length, 1);

  const isMoreThanLimit = valuesArrayLength >= limit;
  const isAllFilled = valuesArray.every(
    (value) => !isEqual(defaultValue(type), value)
  );
  const isAddMoreDisabled = !isAllFilled || isMoreThanLimit;

  return (
    <div>
      <Label>{props.label}</Label>
      {props.message ? (
        <div className={message}>
          <InputMessage type="help">{props.message}</InputMessage>
        </div>
      ) : null}
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          const handleRemove = (index: number) => () => {
            arrayHelpers.remove(index);
          };

          const handleAddMore = () => {
            arrayHelpers.push(defaultValue(type));
          };

          const showRemoveButton = (index: number) => {
            if (index === 0) return false;
            if (typeof array === "object" && array.deletionStrategy === "any") {
              return true;
            }
            return valuesArray.length > 1 && index === valuesArray.length - 1;
          };

          return (
            <>
              {range(0, valuesArrayLength).map((index) => {
                const indexedName = getIndexedName(name, index);
                return (
                  <div key={indexedName} className={row}>
                    <div className={hideLabel}>
                      <AutoFormField
                        {...props}
                        name={indexedName}
                        array={undefined}
                        message={undefined}
                      />
                    </div>
                    {showRemoveButton(index) && (
                      <div className={removeButton}>
                        <Button
                          variant="ghost"
                          type="button"
                          onClick={handleRemove(index)}
                          leftIcon="trash"
                          children={null}
                          data-testid="array-remove-button"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
              <div className={addMoreButton}>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={handleAddMore}
                  disabled={isAddMoreDisabled}
                >
                  {addButtonLabel}
                </Button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
}
