import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  MultiSelect,
  FormField,
  Select,
  SelectOption,
  MultiSelectItem,
  TextInput,
  SingleDatePicker,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps } from "formik";
import omit from "lodash/omit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import styles from "../../ProfileInfo.module.css";
import { NATIONALITIES } from "../../constants";
import BaseFormFragment, {
  initialValues as BASE_INITIAL_VALUES,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as IDENTIFIER_NUMBER_INITIAL_VALUES,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
import {
  GENDER_OPTIONS,
  SHG_FUNDS_OPTIONS,
  CITIZENSHIP_STATUS_OPTIONS,
} from "./constants.sg";
import { compactTaxId } from "../../taxIdUtils";
import { isoDateString } from "pages/employer-of-record/employee-profile/utils";
import { ChangeEvent } from "react";

const now = new Date();
const ONE_YEAR_FROM_NOW = new Date(now.setFullYear(now.getFullYear() + 1));

const { inputField } = styles;

const state_key = "profile-info";

const ProfileInfoSG: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const countrySchema = Yup.object({
    nationality: Yup.string().required("This field is required."),
    sgGender: Yup.string()
      .oneOf(GENDER_OPTIONS.map((t) => t.value))
      .required(t("Gender is required")),
    sgShgFunds: Yup.array()
      .of(Yup.string().required("SHG Fund value is required."))
      .min(1, "At least one SHG Fund is required.")
      .required("SHG Funds are required."),
    sgCitizenshipStatus: Yup.string().required("This field is required."),
    sgRace: Yup.string().required("This field is required."),
    sgPermanentResidenceStartDate: Yup.string().when(
      "sgCitizenshipStatus",
      ([sgCitizenshipStatus], schema) => {
        if (!sgCitizenshipStatus) {
          return schema.optional();
        }

        return sgCitizenshipStatus === "permanent-resident"
          ? schema.required("This field is required")
          : schema.optional();
      }
    ),
  });

  const validationSchema = countrySchema
    .concat(baseSchema(t))
    .concat(identifierNumberSchema(t, "SG"));

  type CountrySchema = Yup.InferType<typeof countrySchema>;
  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const INITIAL_VALUES: CountrySchema = {
    nationality: "",
    sgGender: "",
    sgShgFunds: [] as string[],
    sgCitizenshipStatus: "",
    sgRace: "",
    sgPermanentResidenceStartDate: "", //TODO: date format with back state issue https://justworks.atlassian.net/browse/IEORN-3744
  } as const;

  const DEFAULT_VALUES = {
    ...BASE_INITIAL_VALUES,
    ...IDENTIFIER_NUMBER_INITIAL_VALUES,
    ...INITIAL_VALUES,
  } as const;

  const getInitialValues = (): ValidationSchema => {
    if (!profileInfo?.profileInfo) return DEFAULT_VALUES;

    const sanitizedValues = omit(profileInfo?.profileInfo);

    return {
      ...DEFAULT_VALUES,
      ...sanitizedValues,
    };
  };

  const onFormSubmit = (values: ValidationSchema) => {
    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values,
        identifierNumber: profileInfoIdentifierNumber,
        confirmIdentifierNumber: undefined,
        sgPermanentResidenceStartDate:
          values.sgPermanentResidenceStartDate === ""
            ? null
            : values.sgPermanentResidenceStartDate,
      },
    });
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            values,
          }: FormikProps<ValidationSchema>) => {
            const fieldErrorHandler = (name: keyof ValidationSchema) => {
              if (!errors || !touched) return;
              if (errors[name] && touched[name]) {
                return errors[name]?.toString();
              }
            };

            const handleDateChange = (date: Date) => {
              setFieldValue(
                "sgPermanentResidenceStartDate",
                isoDateString(date)
              );
            };

            const handleCitizenshipOnChange = (
              event: ChangeEvent<HTMLSelectElement>
            ) => {
              setFieldValue("sgPermanentResidenceStartDate", "");
              return handleChange(event);
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />
                  <div className={inputField}>
                    <FormField
                      required
                      name="nationality"
                      label={t("Nationality")}
                      error={fieldErrorHandler("nationality")}
                    >
                      <Select
                        name="nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.nationality)}
                        placeholder={
                          values.nationality.length
                            ? undefined
                            : t("Select Nationality")
                        }
                      >
                        {NATIONALITIES.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      name="sgGender"
                      required
                      label={t("Gender")}
                      error={touched?.sgGender ? errors?.sgGender : ""}
                      message={t(
                        "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences."
                      )}
                    >
                      <Select
                        name="sgGender"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.sgGender}
                        placeholder={t("Select Gender")}
                      >
                        {GENDER_OPTIONS.map((gender) => (
                          <SelectOption value={gender.value} key={gender.value}>
                            {gender.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      required
                      name="sgCitizenshipStatus"
                      label={t("Citizenship Status")}
                      error={fieldErrorHandler("sgCitizenshipStatus")}
                    >
                      <Select
                        name="sgCitizenshipStatus"
                        onChange={handleCitizenshipOnChange}
                        onBlur={handleBlur}
                        defaultValue={values.sgCitizenshipStatus}
                        placeholder={t("Select Citizenship status")}
                      >
                        {CITIZENSHIP_STATUS_OPTIONS.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  {values.sgCitizenshipStatus !== "citizen" && (
                    <div className={inputField}>
                      <FormField
                        error={
                          touched?.sgPermanentResidenceStartDate
                            ? errors?.sgPermanentResidenceStartDate
                            : ""
                        }
                        label={t("Date permanent residence began")}
                        required={
                          values.sgCitizenshipStatus === "permanent-resident"
                        }
                      >
                        <SingleDatePicker
                          id="sgPermanentResidenceStartDate"
                          name="sgPermanentResidenceStartDate"
                          onBlur={handleBlur}
                          onChange={handleDateChange}
                          value={values.sgPermanentResidenceStartDate}
                          maxDate={ONE_YEAR_FROM_NOW}
                        />
                      </FormField>
                    </div>
                  )}
                  <div className={inputField}>
                    <FormField
                      required
                      name="sgRace"
                      label={t("Race")}
                      error={fieldErrorHandler("sgRace")}
                    >
                      <TextInput
                        name="sgRace"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.sgRace)}
                      />
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      required
                      name="sgShgFunds"
                      label={t("Self Help Group Funds (SHG)")}
                      error={fieldErrorHandler("sgShgFunds")}
                      message={t(
                        "Employers in Singapore are expected to deduct contributions to the appropriate SHG fund from their employees' wages, based on the employees race or religion as indicated on their National Registration Identity Card. For more information or to change your contributions, visit the Central Provident Fund Board (CPFB) website."
                      )}
                    >
                      <MultiSelect
                        values={values.sgShgFunds}
                        onChange={(selectedItems: MultiSelectItem[]) => {
                          const selectedValues = selectedItems.map(
                            (item) => item.value as string
                          );
                          setFieldValue("sgShgFunds", selectedValues);
                        }}
                        onBlur={handleBlur}
                      >
                        {SHG_FUNDS_OPTIONS.map((fund) => (
                          <SelectOption value={fund.value} key={fund.value}>
                            {fund.description}
                          </SelectOption>
                        ))}
                      </MultiSelect>
                    </FormField>
                  </div>
                  <IdentifierNumberFormFragment workCountry="SG" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoSG;
