import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";

export const indiaFields = {
  inPan: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "identifierNumber",
    label: "Permanent account number (PAN)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("IN"),
    },
    value: personalInfo("identifierNumber", ""),
  }),
  inPfUan: field({
    type: FieldTypes.text,
    informationType: "benefits",
    name: "inPfUan",
    label: "PF UAN",
    scenarios: {
      viewProfile: countryIs("IN"),
    },
    value: personalInfo("inPfUan", ""),
  }),
  inEsic: field({
    type: FieldTypes.text,
    informationType: "benefits",
    name: "inEsic",
    label: "ESIC Number",
    scenarios: {
      viewProfile: countryIs("IN"),
    },
    value: personalInfo("inEsic", ""),
  }),
};
