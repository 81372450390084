import { fetchClient } from "@justworkshr/fe-utility-kit";
import { useQuery } from "@tanstack/react-query";
import { buildClockworkWebPath } from "lib/resource-finder";

interface OnboardingCheckResponse {
  is_onboarding: boolean;
  is_on_reverse_wire?: boolean;
}

export const useIsOnboardingCheck = () => {
  const { isLoading, data, error } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ["is_onboarding_check"],
    queryFn: async () => {
      const response = await fetchClient.get(
        buildClockworkWebPath("/company/bank_account/is_onboarding")
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: OnboardingCheckResponse = await response.json();

      return data;
    },
  });
  return { isLoading, data, error };
};
