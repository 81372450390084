import { ReactElement, useEffect } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import EmployeesEORImage from "../../images/employees-eor.webp";
import CostCalculatorCard from "pages/international-solutions/components/Cards/CostCalculatorCard";
import { EORFAQ } from "pages/international-solutions/components/FAQ/EORFAQ";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import { useGetCompanyDisplayQuery } from "types/generated/operations";
import {
  CUSTOMER_IO_PAGEVIEW_EVENT,
  sendCustomerIOEvent,
} from "app/customer-io";
import EORPriceCard from "pages/international-solutions/components/Cards/EORPriceCard";

const EmployerOfRecord = (): ReactElement => {
  const {
    container,
    column1,
    column2,
    divWrapper,
    mdFont,
    mdGap,
    lgGap,
    lgHead,
    listWrapper,
    xsHead,
    hr,
    infoText,
    price,
    listItems,
  } = styles;

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();

  useEffect(() => {
    // memberData to be available to avoid redundant calls
    if (!memberData?.authenticatedMember) return;

    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_PAGEVIEW_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: { page_url: window.location.href },
      },
      accessToken
    );
  }, [accessToken, memberData]);

  return (
    <>
      <div className={container}>
        <div className={column1}>
          <img src={EmployeesEORImage} alt="" />
          <div className={`${divWrapper} ${lgGap}`}>
            <div className={lgHead}>
              Hire and pay international employees with ease
            </div>
            <div className={mdFont}>
              Justworks makes global growth easy for you. . With a robust HR
              platform designed for your small business, you can onboard and
              manage international employees seamlessly, saving time, money, and
              effort.
            </div>
            <div>
              <ul className={listWrapper}>
                <li>
                  <div className={xsHead}>
                    Simple HR tools and dedicated support
                  </div>
                  Speed up hiring and onboarding in new countries with our
                  easy-to-use platform and in-house customer support so your
                  employees have access to benefits they value.
                </li>
                <li>
                  <div className={xsHead}>Seamless global team management </div>
                  Justworks all-in-one platform allows you to build and manage a
                  unified, high-performing workforce across borders.
                </li>
                <li>
                  <div className={xsHead}>Stress-free compliance</div>
                  We handle all the complicated international compliance tasks
                  so you can focus on your business success.
                </li>
              </ul>
            </div>
          </div>

          <hr className={hr}></hr>
          <div className={`${divWrapper} ${mdGap}`}>
            <div className={lgHead}>Justworks EOR pricing</div>
            <div>
              <p>Fees apply for each international employee per month.</p>
              <div className={infoText}>
                <span className={price}>$599</span>/month per employee
              </div>
              <h3>Key Features</h3>
              <ul className={listItems}>
                <li>No setup fees and no minimums</li>
                <li>Easy onboarding workflows</li>
                <li>Compliance expertise from an award-winning support team</li>
                <li>Localized employee benefits</li>
              </ul>
            </div>
          </div>

          <div className={`${divWrapper} ${mdGap}`}>
            <EORFAQ />
          </div>
        </div>

        <div className={`${column2}`}>
          <div className={`${divWrapper} ${lgGap}`}>
            <EORPriceCard />
            <CostCalculatorCard />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployerOfRecord;
