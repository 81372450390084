import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import { ActionFooter, Form } from "@justworkshr/milo-form";
import { Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import styles from "../../ProfileInfo.module.css";
import { compactTaxId } from "../../taxIdUtils";
import BaseFormFragment, {
  initialValues as BASE_INITIAL_VALUES,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as IDENTIFIER_NUMBER_INITIAL_VALUES,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
import { initialValues, useFields } from "pages/employer-of-record/configs";
import {
  AutoFormField,
  generateFormValidations,
} from "pages/employer-of-record/components/AutoForm";
import { useInScopeMemberData } from "pages/employer-of-record/configs/hooks/useInScopeMemberData";
import { eraseSensitiveValues } from "pages/employer-of-record/employee-profile/utils";
import { australiaFields } from "pages/employer-of-record/configs/countries/auFields";
import partition from "lodash/partition";

const state_key = "profile-info";
const PRE_BASE_FIELDS = [australiaFields.auTitle.name];

const ProfileInfoGeneric: FC = () => {
  const { t } = useTranslation();
  const memberDataScope = useInScopeMemberData();
  const allFields = useFields({
    scenario: "onboarding",
  });
  const [preBaseFields, fields] = partition(allFields, (field) =>
    PRE_BASE_FIELDS.includes(field.name)
  );
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const schemas = {
    base: baseSchema(t),
    identiferNumber: identifierNumberSchema(t, workCountry ?? ""),
    country: generateFormValidations(fields, t),
  };

  const commonSchema = schemas.base.concat(schemas.identiferNumber);
  const validationSchema = commonSchema.concat(schemas.country);

  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  let DEFAULT_VALUES: ValidationSchema = {
    ...BASE_INITIAL_VALUES,
    ...IDENTIFIER_NUMBER_INITIAL_VALUES,
    ...profileInfo?.profileInfo,
  };

  if (memberDataScope?.eorEmployeeProfile) {
    DEFAULT_VALUES = {
      ...DEFAULT_VALUES,
      ...eraseSensitiveValues(
        initialValues(fields, memberDataScope?.eorEmployeeProfile)
      ),
    };
  }

  const onFormSubmit = (values: ValidationSchema) => {
    const rawIdentiferNumber =
      "identifierNumber" in values &&
      typeof values.identifierNumber === "string"
        ? values.identifierNumber
        : undefined;

    if (!rawIdentiferNumber) {
      throw new Error("No identifier number!");
    }

    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      rawIdentiferNumber
    );

    onSubmit({
      profileInfo: {
        ...values,
        identifierNumber: profileInfoIdentifierNumber,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={DEFAULT_VALUES}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  {!!preBaseFields.length && (
                    <div className={styles.fields}>
                      {preBaseFields.map((field) => (
                        <AutoFormField key={field.name} {...field} />
                      ))}
                    </div>
                  )}

                  <BaseFormFragment />

                  <div className={styles.fields}>
                    {fields.map((field) => (
                      <AutoFormField key={field.name} {...field} />
                    ))}
                  </div>

                  <IdentifierNumberFormFragment
                    workCountry={workCountry ?? ""}
                  />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoGeneric;
