export interface TaxDocumentConfig {
  name: string;
  required: boolean;
  cardTitle: string;
  cardDescriptionInitialText?: string;
  cardDescriptionLinks?: TaxDocumentCardLink[];
}

export interface TaxDocumentCardLink {
  linkText: string;
  afterLinkText: string;
  linkUrl: string;
}

export const TaxInfoConfig: Record<string, TaxDocumentConfig[]> = {
  AU: [
    {
      name: "auSuperannuationStandardChoiceForm",
      required: true,
      // t("auSuperannuationStandardChoiceForm.cardTitle")
      cardTitle: "auSuperannuationStandardChoiceForm.cardTitle",
    },
    {
      name: "auTfn",
      required: true,
      // t("auTfn.cardTitle")
      cardTitle: "auTfn.cardTitle",
    },
  ],
  BR: [
    {
      name: "brCtps",
      required: true,
      // t("brCtps.cardTitle")
      cardTitle: "brCtps.cardTitle",
    },
  ],
  CA: [
    {
      name: "caTd1FederalForm",
      required: true,
      // t("caTd1FederalForm.cardTitle")
      cardTitle: "caTd1FederalForm.cardTitle",
      // t("caTd1FederalForm.cardDescriptionInitialText")
      cardDescriptionInitialText: "caTd1FederalForm.cardDescriptionInitialText",
      cardDescriptionLinks: [
        {
          // t("caTd1FederalForm.linkText")
          linkText: "caTd1FederalForm.linkText",
          // t("caTd1FederalForm.afterLinkText")
          afterLinkText: "caTd1FederalForm.afterLinkText",
          linkUrl:
            "https://www.canada.ca/en/revenue-agency/services/forms-publications/td1-personal-tax-credits-returns/td1-forms-pay-received-on-january-1-later/td1.html",
        },
      ],
    },
    {
      name: "caTd1ProvincialForm",
      required: true,
      // t("caTd1ProvincialForm.cardTitle")
      cardTitle: "caTd1ProvincialForm.cardTitle",
      // t("caTd1ProvincialForm.cardDescriptionInitialText")
      cardDescriptionInitialText:
        "caTd1ProvincialForm.cardDescriptionInitialText",
      cardDescriptionLinks: [
        {
          // t("caTd1QCProvincialForm.linkText")
          linkText: "caTd1QCProvincialForm.linkText",
          // t("caTd1QCProvincialForm.afterLinkText")
          afterLinkText: "caTd1QCProvincialForm.afterLinkText",
          linkUrl:
            "https://www.revenuquebec.ca/en/online-services/forms-and-publications/current-details/tp-1015-3-v/",
        },
        {
          // t("caTd1BCProvincialForm.linkText")
          linkText: "caTd1BCProvincialForm.linkText",
          // t("caTd1BCProvincialForm.afterLinkText")
          afterLinkText: "caTd1BCProvincialForm.afterLinkText",
          linkUrl:
            "https://www.canada.ca/en/revenue-agency/services/forms-publications/td1-personal-tax-credits-returns/td1-forms-pay-received-on-january-1-later.html",
        },
      ],
    },
  ],
  CL: [
    {
      name: "clAfp",
      required: true,
      // t("clAfp.cardTitle")
      cardTitle: "clAfp.cardTitle",
    },
    {
      name: "clAhs",
      required: true,
      // t("clAhs.cardTitle")
      cardTitle: "clAhs.cardTitle",
    },
  ],
  ES: [
    {
      name: "esModelo145",
      required: true,
      // t("esModelo145.cardTitle")
      cardTitle: "esModelo145.cardTitle",
    },
  ],
  FR: [
    {
      name: "frDpae",
      required: true,
      // t("frDpae.cardTitle")
      cardTitle: "frDpae.cardTitle",
    },
  ],
  GB: [
    {
      name: "gbP45Form",
      required: true,
      // t("gbP45Form.cardTitle")
      cardTitle: "gbP45Form.cardTitle",
      // t("gbP45Form.cardDescriptionInitialText")
      cardDescriptionInitialText: "gbP45Form.cardDescriptionInitialText",
      cardDescriptionLinks: [
        {
          // t("gbP45Form.linkText")
          linkText: "gbP45Form.linkText",
          // t("gbP45Form.afterLinkText")
          afterLinkText: "gbP45Form.afterLinkText",
          linkUrl:
            "https://assets.publishing.service.gov.uk/media/6331a05f8fa8f51d29278ebc/Starter_checklist.pdf",
        },
      ],
    },
  ],
  IN: [
    {
      name: "inFormB12",
      required: true,
      // t("inFormB12.cardTitle")
      cardTitle: "inFormB12.cardTitle",
    },
  ],
  MX: [
    {
      name: "mxBankAccountStatement",
      required: true,
      // t("mxBankAccountStatement.cardTitle")
      cardTitle: "mxBankAccountStatement.cardTitle",
    },
    {
      name: "mxCsf",
      required: true,
      // t("mxCsf.cardTitle")
      cardTitle: "mxCsf.cardTitle",
    },
    {
      name: "mxProofOfAddress",
      required: true,
      // t("mxProofOfAddress.cardTitle")
      cardTitle: "mxProofOfAddress.cardTitle",
    },
  ],
  CO: [
    {
      name: "coRegistroUnicoTributario",
      required: true,
      // t("coRegistroUnicoTributario.cardTitle")
      cardTitle: "coRegistroUnicoTributario.cardTitle",
    },
    {
      name: "coEps",
      required: true,
      // t("coEps.cardTitle")
      cardTitle: "coEps.cardTitle",
    },
    {
      name: "coSeveranceFund",
      required: true,
      // t("coSeveranceFund.cardTitle")
      cardTitle: "coSeveranceFund.cardTitle",
    },
    {
      name: "coPensionFund",
      required: true,
      // t("coPensionFund.cardTitle")
      cardTitle: "coPensionFund.cardTitle",
    },
    {
      name: "coBankAccountStatement",
      required: true,
      // t("coBankAccountStatement.cardTitle")
      cardTitle: "coBankAccountStatement.cardTitle",
    },
  ],
  NL: [
    {
      name: "nlPayrollTaxes",
      required: true,
      // t("nlPayrollTaxes.cardTitle")
      cardTitle: "nlPayrollTaxes.cardTitle",
      cardDescriptionInitialText: "Upload your completed ",
      cardDescriptionLinks: [
        {
          linkText: "statement of data for payroll taxes",
          afterLinkText:
            " to ensure the right amount of tax is taken from your pay according to Dutch regulations.",
          linkUrl:
            "https://download.belastingdienst.nl/belastingdienst/docs/stat-of-dat-payroll-taxes-lh0082z5foleng.pdf",
        },
      ],
    },
  ],
  PH: [
    {
      name: "phForm1902",
      required: true,
      // t("phForm1902.cardTitle")
      cardTitle: "phForm1902.cardTitle",
    },
  ],
  PT: [
    {
      name: "ptDeclaracao99",
      required: true,
      // t("ptDeclaracao99.cardTitle")
      cardTitle: "ptDeclaracao99.cardTitle",
      cardDescriptionInitialText: "Upload your completed ",
      cardDescriptionLinks: [
        {
          linkText: "Declaração 99",
          afterLinkText:
            " to ensure the right amount of tax is withheld from your pay according to Portuguese regulations.",
          linkUrl:
            "https://www.fd.ulisboa.pt/wp-content/uploads/2014/12/declaracao-99-IRS.pdf",
        },
      ],
    },
    {
      name: "ptErf",
      required: true,
      // t("ptErf.cardTitle")
      cardTitle: "ptErf.cardTitle",
      cardDescriptionInitialText: "Upload your completed ",
      cardDescriptionLinks: [
        {
          linkText: "Employee Registration Form",
          afterLinkText:
            " to confirm your eligibility for social benefits and accurately record your contributions.",
          linkUrl:
            "https://www.seg-social.pt/documents/10152/21741/RV_1009_DGSS/2a3a1e22-e0da-421a-8b6d-93f9480a16f8",
        },
      ],
    },
  ],
  SE: [
    {
      name: "seATax",
      required: true,
      // t("seATax.cardTitle")
      cardTitle: "seATax.cardTitle",
    },
  ],
};
