import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";

export const netherlandsFields = {
  nlBsn: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "nlBsn",
    label: "BSN (Burger Service Number)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("NL"),
    },
    value: personalInfo("identifierNumber", ""),
  }),
};
