import { useTranslation } from "react-i18next";
import { EOR_PROFILE_PATH } from "pages/employer-of-record/routes";
import { ACCOUNT_SETTINGS_SUBPATHS, SUBPATHS } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import RequestChangesAction from "../RequestChangesAction/RequestChangesAction";
import CardAction from "../CardAction/CardAction";
import { ACCOUNT_SETTINGS_PATH } from "../../../../index";
import { InformationType } from "pages/employer-of-record/components/AutoForm";

type Props = {
  target: InformationType;
  enabled: boolean;
};

export default function EditAction({ target, enabled }: Props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = !!id;

  const BASIC_INFO_PATH = ACCOUNT_SETTINGS_SUBPATHS.BASIC_INFO_PATH;
  const EDIT_PROFILE_PATH = ACCOUNT_SETTINGS_SUBPATHS.EDIT_PROFILE_PATH;

  function navigateToEditPage() {
    const employeeProfileAdminPath = `/employer-of-record/${EOR_PROFILE_PATH}/${id}/${SUBPATHS.EDIT}#${target}`;
    const accountSettingsPath = `/${ACCOUNT_SETTINGS_PATH}/${BASIC_INFO_PATH}/${EDIT_PROFILE_PATH}#${target}`;
    navigate(isAdmin ? employeeProfileAdminPath : accountSettingsPath);
  }

  if (enabled) {
    return (
      <CardAction name={t("Edit")} icon="edit" onClick={navigateToEditPage} />
    );
  } else {
    return <RequestChangesAction />;
  }
}
