import { Button, Notice, Stack } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  RadioButton,
  RadioButtonGroup,
  SingleDatePicker,
  TextInput,
} from "@justworkshr/milo-form";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { RequestSeparationFormType } from "../types";
import { useRequestSeparationContext } from "../context/RequestSeparationContext";
import { addYears, formatToUSDate } from "pages/employer-of-record/utils";

const oneYearFromToday = addYears(new Date(), 1);
const schema = Yup.object().shape({
  desiredLastDay: Yup.string().required("Required"),
  separationType: Yup.string().required("Required"),
  separationReason: Yup.string().required("Required"),
});

const RequestSeparationForm = () => {
  const { t } = useTranslation();
  const {
    formValues: initialValues,
    setFormValues,
    setCurrentStep,
  } = useRequestSeparationContext();

  const onSubmit = (values: RequestSeparationFormType) => {
    setFormValues(values);
    setCurrentStep(2);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        values,
        setFieldValue,
      }: FormikProps<RequestSeparationFormType>) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Stack spacing="2xl">
              <FormField
                name="desiredLastDay"
                label={t("Desired last day of work")}
                error={errors.desiredLastDay}
                required
              >
                <SingleDatePicker
                  name="desiredLastDay"
                  minDate={new Date()}
                  maxDate={oneYearFromToday}
                  onChange={(date) => {
                    setFieldValue("desiredLastDay", formatToUSDate(date));
                  }}
                  value={values.desiredLastDay}
                />
              </FormField>

              <Notice
                title={t("This desired last day of work is subject to change")}
              >
                {t(
                  "We need time to review your request and ensure compliance with local employment law."
                )}
              </Notice>
              <FormField
                name="separationType"
                label={t("Separation type")}
                required
                error={errors.separationType}
              >
                <RadioButtonGroup
                  name="separationType"
                  orientation="horizontal"
                  variant="framed"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.separationType}
                >
                  <RadioButton
                    description={t(
                      "Employee informed the company of their resignation."
                    )}
                    label={t("Voluntary")}
                    value="voluntary"
                    data-testid="voluntary"
                  />
                  <RadioButton
                    description={t(
                      "The Company has decided to initiate the separation process."
                    )}
                    label={t("Involuntary")}
                    value="involuntary"
                    data-testid="involuntary"
                  />
                </RadioButtonGroup>
              </FormField>
              <FormField
                label={t("Reason for separation")}
                required
                error={errors.separationReason}
                name="separationReason"
              >
                <TextInput
                  type="text"
                  name="separationReason"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.separationReason}
                />
              </FormField>
              <ActionFooter
                actions={[
                  <Button color="brand" key="submit-btn" type="submit">
                    {t("Next")}
                  </Button>,
                ]}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RequestSeparationForm;
