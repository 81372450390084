import { common } from "./countries/_common";
import { irelandFields } from "./countries/ieFields";
import { mexicoFields } from "./countries/mxFields";
import { brazilFields } from "./countries/brFields";
import { portugalFields } from "./countries/ptFields";
import { spainFields } from "./countries/esFields";
import { colombiaFields } from "./countries/coFields";
import { indiaFields } from "./countries/inFields";
import { netherlandsFields } from "./countries/nlFields";
import { australiaFields } from "./countries/auFields";
import { philippinesFields } from "./countries/phFields";

export const PROFILE_FIELDS = {
  ...common,
  ...irelandFields,
  ...mexicoFields,
  ...brazilFields,
  ...portugalFields,
  ...spainFields,
  ...colombiaFields,
  ...indiaFields,
  ...netherlandsFields,
  ...australiaFields,
  ...philippinesFields,
};

export type ProfileFieldName = keyof typeof PROFILE_FIELDS;
export type ProfileFieldData = {
  [K in ProfileFieldName]: ReturnType<(typeof PROFILE_FIELDS)[K]["value"]>;
};
