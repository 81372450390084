import { ReactElement } from "react";
import { Accordion, AccordionPanel } from "@justworkshr/milo-core";
import styles from "../../InternationalSolutionsPage.module.css";

export const ContractorsFAQ = (): ReactElement => {
  const { componentWrapper, mdHead, faqLink } = styles;
  return (
    <div className={componentWrapper}>
      <div className={mdHead}>FAQs</div>
      <div className={componentWrapper}>
        <Accordion>
          <AccordionPanel isOpen title="How many countries are supported?">
            You can pay contractors in 50+ countries—with plans to add more
            soon. See a
            <a
              href="https://help.justworks.com/hc/en-us/articles/18040350606619-International-Contractors"
              target="_blank"
            >
              {" "}
              <span className={faqLink}>
                full list of supported countries
              </span>{" "}
            </a>
            in the Help Center.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="Will I be able to send payments to employees or businesses outside of the US?"
          >
            We support employee and contractor payments through our EOR and
            International Contractor Payment services.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="Are these payments considered a wire transfer?"
          >
            Payments run via local automated clearinghouse (ACH), not wires,
            which are typically cheaper, offer better exchange rates, and are
            more reliable outside the northwestern hemisphere. You'll see the
            estimated exchange rate when setting up payments, which are
            currently USD-denominated, with conversions to the contractor’s
            local currency.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="How long does it take for a payment to appear in a contractor’s bank account?"
          >
            Depending on the country, payments take between 5–7 business days to
            land in a bank account.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="What about taxes? Where is the end of year checklist?"
          >
            Unlike domestic contractors (US), we don’t provide year-end tax
            documents for international contractors, as many countries don’t
            require them without a local entity. We recommend consulting your
            legal counsel to ensure compliance with local requirements.
          </AccordionPanel>
        </Accordion>
      </div>
    </div>
  );
};
