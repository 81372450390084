import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import { ActionFooter, Form } from "@justworkshr/milo-form";
import { Formik, type FormikProps } from "formik";
import omit from "lodash/omit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import BaseFormFragment, {
  initialValues as BASE_INITIAL_VALUES,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as IDENTIFIER_NUMBER_INITIAL_VALUES,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
import { compactTaxId } from "../../taxIdUtils";

const state_key = "profile-info";

const ProfileInfoSE: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const countrySchema = Yup.object({});

  const validationSchema = countrySchema
    .concat(baseSchema(t))
    .concat(identifierNumberSchema(t, "SE"));

  type CountrySchema = Yup.InferType<typeof countrySchema>;
  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const INITIAL_VALUES: CountrySchema = {} as const;

  const DEFAULT_VALUES = {
    ...BASE_INITIAL_VALUES,
    ...IDENTIFIER_NUMBER_INITIAL_VALUES,
    ...INITIAL_VALUES,
  } as const;

  const getInitialValues = (): ValidationSchema => {
    if (!profileInfo?.profileInfo) return DEFAULT_VALUES;

    const sanitizedValues = omit(profileInfo?.profileInfo);

    return {
      ...DEFAULT_VALUES,
      ...sanitizedValues,
    };
  };

  const onFormSubmit = (values: ValidationSchema) => {
    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values,
        identifierNumber: profileInfoIdentifierNumber,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }: FormikProps<ValidationSchema>) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  <IdentifierNumberFormFragment workCountry="SE" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoSE;
