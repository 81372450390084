import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";
import {
  TITLE_OPTIONS,
  GENDER_OPTIONS,
} from "pages/employer-of-record/onboarding/profile-info/form/au/constants.au";

export const australiaFields = {
  auTitle: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "auTitle",
    label: "Title",
    placeholder: "Select Title",
    options: TITLE_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("AU"),
    },
    value: () => "", // TODO: Expose auTitle
  }),
  auGender: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "auGender",
    label: "Gender",
    placeholder: "Select Gender",
    message:
      "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences.",
    options: GENDER_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("AU"),
    },
    value: () => "", // TODO: Expose auGender
  }),
  auTfn: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "identifierNumber",
    label: "TFN",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("AU"),
    },
    value: personalInfo("identifierNumber", ""),
  }),
};
