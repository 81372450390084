import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, isUser, personalInfo, REQUIRED } from "./utils";

export const philippinesFields = {
  phSsn: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "phSsn",
    label: "SSS (Social Security System) Number",
    validations: {
      ...REQUIRED,
      text: {
        length: {
          value: 10,
          message: "Must be a valid SSS number.",
        },
      },
    },
    scenarios: {
      viewProfile: countryIs("PH"),
      onboarding: countryIs("PH"),
    },
    value: personalInfo("phSsn", ""),
  }),
  phPhicn: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "phPhicn",
    label: "PHIC (Philippine Health Insurance Corporation) Number",
    validations: {
      ...REQUIRED,
      text: {
        length: {
          value: 12,
          message: "Must be a valid PhilHealth number.",
        },
      },
    },
    scenarios: {
      viewProfile: countryIs("PH"),
      editProfile: (profile, authenticatedMember) => {
        return isUser(profile, authenticatedMember) && countryIs("PH")(profile);
      },
      onboarding: countryIs("PH"),
    },
    value: personalInfo("phPhicn", ""),
  }),
  phHdmfn: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "phHdmfn",
    label: "HDMF (Home Development Mutual Fund) Number",
    validations: {
      ...REQUIRED,
      text: {
        length: {
          value: 12,
          message: "Must be a valid HDMF number.",
        },
      },
    },
    scenarios: {
      viewProfile: countryIs("PH"),
      editProfile: (profile, authenticatedMember) => {
        return isUser(profile, authenticatedMember) && countryIs("PH")(profile);
      },
      onboarding: countryIs("PH"),
    },
    value: personalInfo("phHdmfn", ""),
  }),
  phTin: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "phTin",
    label: "Taxpayer Identification Number (TIN)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("PH"),
    },
    value: personalInfo("identifierNumber", ""),
  }),
};
