import { Alert, PageHeader, Spinner } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import styles from "./EditBankAccountPage.module.css";
import { useProfileContextData } from "../contexts/ProfileInfoContext";
import { useState } from "react";
import EditBankAccount from "../components/EditBankAccount/EditBankAccount";
import { useNavigate } from "react-router-dom";
import { useEditEorEmployeeBankAccountMutation } from "types/generated/operations";
import { ApolloError } from "@apollo/client";
import useNavigateToBankAccountEditing from "../hooks/useNavigateToBankAccountEditing";

type SubmitVariables = NonNullable<
  NonNullable<
    Parameters<ReturnType<typeof useEditEorEmployeeBankAccountMutation>[0]>[0]
  >["variables"]
>;

export default function EditBankAccountPage() {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const [editEorEmployeBankAccount] = useEditEorEmployeeBankAccountMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const navigateToBankAccountEditing = useNavigateToBankAccountEditing();
  const navigate = useNavigate();

  const bankAccount = profileInfo?.eorEmployeeProfile.bankAccount;

  if (!bankAccount) return <Spinner />;

  function navigateToAccountSettings() {
    navigate(
      `/account-settings/basic-information/finances?updated=${encodeURIComponent(
        "Successfully updated your bank information."
      )}`
    );
  }

  async function onSubmit(variables: SubmitVariables) {
    setErrorMessage(undefined);

    try {
      await editEorEmployeBankAccount({ variables });
      navigateToAccountSettings();
    } catch (e) {
      const { graphQLErrors } = e as ApolloError;
      const errorCode = graphQLErrors?.[0]?.extensions.code;

      if (errorCode === 400) {
        setErrorMessage(
          "Reauthentication is required before editing bank account details. Redirecting to the login page now..."
        );

        navigateToBankAccountEditing();
      } else {
        console.error(e);
        setErrorMessage(
          t("An unknown error occurred. Please try again later.")
        );
      }
    }
  }

  return (
    <div className={styles.container}>
      {errorMessage && <Alert color="destructive">{errorMessage}</Alert>}
      <PageHeader title={t("Edit your bank information")} />
      <EditBankAccount bankAccount={bankAccount} onSubmit={onSubmit} />
    </div>
  );
}
