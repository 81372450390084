import { ReactElement } from "react";

import styles from "../../InternationalSolutionsPage.module.css";
import { Accordion, AccordionPanel } from "@justworkshr/milo-core";

export const EORFAQ = (): ReactElement => {
  const { componentWrapper, mdHead } = styles;
  return (
    <div className={componentWrapper}>
      <div className={mdHead}>FAQs</div>
      <div className={componentWrapper}>
        <Accordion>
          <AccordionPanel isOpen title="What is an Employer of Record?">
            An Employer of Record (EOR) helps companies hire employees locally,
            manage payroll, and administer benefits—without setting up a local
            entity. EOR simplifies global hiring by handling complex
            regulations, allowing businesses to hire internationally with ease.
          </AccordionPanel>
          <AccordionPanel isOpen title="How do Justworks’ EOR services work?">
            Our Employer of Record (EOR) serves as a strategic partner, with
            local legal and HR experts simplifying international hiring. Your
            company retains hiring decisions and manages day-to-day activities,
            while we handle HR, payroll, and compliance.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="What is the difference between EOR and International Contractor Payments?"
          >
            <div>
              Justworks' global EOR services enables companies to hire, pay, and
              provide benefits to employees based around the world.
            </div>
            <br></br>
            <div>
              Justworks' International Contractor Payments allows companies to
              pay international contractors in 50+ countries, in their preferred
              currency.
            </div>
          </AccordionPanel>
        </Accordion>
      </div>
    </div>
  );
};
