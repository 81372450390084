import { FormField } from "@justworkshr/milo-form";
import { useTranslation } from "react-i18next";
import { getIn, useFormikContext } from "formik";
import {
  ArrayFieldDataType,
  FieldDataType,
  FieldTypes,
  ValidationType,
} from "./types";
import AutoFormInput from "./AutoFormInput";
import AutoFormFieldArray from "./AutoFormFieldArray";
import AddressForm from "../InternationalAddressForm/AddressForm";
import styles from "./AutoFormField.module.css";

type Props = FieldDataType & {
  className?: string | { [key: string]: string };
  optional?: boolean;
  id?: string;
  validations?: ValidationType;
  // Override the display of the `Optional` label on a field. Does not impact validations.
  hideOptional?: boolean;
  format?: (value: string) => string;
  compact?: (value: string) => string;
};

const AutoFormField = (props: Props) => {
  const { type, name, label, message, validations, hideOptional } = props;
  const { t } = useTranslation();
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<Record<string, unknown>>();

  const getError = () => {
    const targetName = type === FieldTypes.phoneNumber ? `${name}.value` : name;

    if (getIn(touched, targetName)) {
      return t(getIn(errors, targetName), validations);
    }

    return "";
  };

  const isRequired = () => {
    if (hideOptional === undefined) {
      return validations?.required.enabled;
    } else {
      if (validations?.required.enabled) {
        return true;
      } else {
        return hideOptional;
      }
    }
  };

  if (props.hideWhen) {
    for (const [name, shouldHide] of Object.entries(props.hideWhen)) {
      if (shouldHide(values[name])) return null;
    }
  }

  if ("array" in props && props.array) {
    return <AutoFormFieldArray {...(props as ArrayFieldDataType)} />;
  } else if (props.type === "address") {
    return (
      <div>
        {props.label && (
          <div className={styles.addressLabel}>{props.label}</div>
        )}
        <AddressForm
          formPath={props.name}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </div>
    );
  } else {
    // TODO: Hide label when input is checkbox
    return (
      <FormField
        name={name}
        label={t(label || "")}
        message={message}
        required={isRequired()}
        error={getError()}
      >
        <AutoFormInput {...props} />
      </FormField>
    );
  }
};

export default AutoFormField;
