import { useGetMemberAccountSettingsQuery } from "types/generated/operations";
import { ProfileInfoProvider } from "../employee-profile/contexts/ProfileInfoContext";
import Profile from "../employee-profile/pages/Profile";
import {
  ACCOUNT_SETTINGS_SUBPATHS,
  SUBPATHS,
  TABNAMES,
} from "../employee-profile/constants";
import { Outlet, Route } from "react-router-dom";
import JobInformation from "../employee-profile/components/JobInformation/JobInformation";
import PersonalInfo from "../employee-profile/components/PersonalInfo/PersonalInfo";
import { SupportedCountriesContextProvider } from "../contexts/supportedCountriesContext";
import Finances from "../employee-profile/components/Finances/Finances";
import EditBankAccountPage from "../employee-profile/pages/EditBankAccountPage";
import EditProfilePage from "../employee-profile/pages/EditProfilePage";
import SecurityLogIn from "./SecurityLogIn";

const TABS = {
  [SUBPATHS.PERSONAL_INFO]: TABNAMES[SUBPATHS.PERSONAL_INFO],
  [SUBPATHS.JOB_INFORMATION]: TABNAMES[SUBPATHS.JOB_INFORMATION],
  finances: "Finances",
  security: "Security/Log In",
};

type Props = {
  editable: boolean;
};

export function subroutes({ editable }: Props) {
  return (
    <Route path="" element={<Wrapper />}>
      <Route path="" element={<Profile tabs={TABS} hideBreadcrumbs />}>
        <Route
          path="personal-info"
          element={<PersonalInfo editable={editable} />}
        />
        <Route
          path="job-information"
          element={<JobInformation editable={false} />}
        />
        <Route path="finances" element={<Finances />} />
        <Route path="security" element={<SecurityLogIn />} />
        <Route path="" element={<PersonalInfo editable={editable} />} />
      </Route>
      {editable && (
        <Route
          path={`${ACCOUNT_SETTINGS_SUBPATHS.EDIT_PROFILE_PATH}`}
          element={<EditProfilePage />}
        />
      )}
      <Route path="edit/bank-account" element={<EditBankAccountPage />} />
    </Route>
  );
}

function Wrapper() {
  const { data } = useGetMemberAccountSettingsQuery();

  return (
    <SupportedCountriesContextProvider>
      <ProfileInfoProvider memberUuid={data?.authenticatedMember.uuid}>
        <Outlet />
        <div data-testid="eor" />
      </ProfileInfoProvider>
    </SupportedCountriesContextProvider>
  );
}
