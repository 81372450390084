import { stdnum, Validator } from "stdnum";
import { ValidationError } from "stdnum/src/exceptions";
import { ValidateReturn } from "stdnum/lib/cjs/types";
import { phTaxIdValidator } from "./TaxIdValidators/phTaxIdValidator";
import { sgTaxIdValidator } from "./TaxIdValidators/sgTaxIdValidator";
import { myTaxIdValidator } from "./TaxIdValidators/myTaxIdValidator";
import { coTaxIdValidator } from "./TaxIdValidators/coTaxIdValidator";
import { ptTaxIdValidator } from "./TaxIdValidators/ptTaxIdValidator";
import { gbTaxIdValidator } from "./TaxIdValidators/gbTaxIdValidator";
import { brTaxIdValidator } from "./TaxIdValidators/brTaxIdValidator";
import { ieTaxIdValidator } from "./TaxIdValidators/ieTaxIdValidator";
import { seTaxIdValidator } from "./TaxIdValidators/seTaxIdValidator";

type TaxIdDataType = {
  type: string;
  length: number;
  fullName: string;
  shortName: string;
};

const countryTaxIdData: Record<string, TaxIdDataType> = {
  AU: {
    type: "tfn",
    length: 9,
    fullName: "Tax File Number (TFN)",
    shortName: "TFN",
  },
  BR: {
    type: "cpf",
    length: 11,
    fullName: "Cadastro de Pessoas Físicas (CPF)",
    shortName: "CPF",
  },
  CA: {
    type: "sin",
    length: 9,
    fullName: "Social Insurance Number (SIN)",
    shortName: "SIN",
  },
  CL: {
    type: "rut",
    length: 11,
    fullName: "Rol Único Tributario (RUT)",
    shortName: "RUT",
  },
  CO: {
    type: "nit",
    length: 10,
    fullName: "Número de Identificación Tributaria (NIT)",
    shortName: "NIT",
  },
  CR: {
    type: "cpf",
    length: 11,
    fullName: "Cédula de Persona Física (CPF)",
    shortName: "CPF",
  },
  DE: {
    type: "idnr",
    length: 11,
    fullName: "Steuer-ID (IDNR)",
    shortName: "IDNR",
  },
  DK: {
    type: "cpr",
    length: 10,
    fullName: "Central Person Register (CPR)",
    shortName: "CPR",
  },
  ES: {
    type: "dni",
    length: 9,
    fullName: "Documento Nacional de Identidad (DNI)",
    shortName: "DNI",
  },
  FR: {
    type: "nif",
    length: 13,
    fullName: "Numéro d'Immatriculation Fiscale (NIF)",
    shortName: "NIF",
  },
  GB: {
    type: "nino",
    length: 9,
    fullName: "National Insurance Number (NINO)",
    shortName: "NINO",
  },
  IE: {
    type: "pps",
    length: 9,
    fullName: "Personal Public Service (PPS)",
    shortName: "PPS",
  },
  IN: {
    type: "pan",
    length: 10,
    fullName: "Permanent Account Number (PAN)",
    shortName: "PAN",
  },
  MX: {
    type: "rfc",
    length: 13,
    fullName: "Registro Federal de Contribuyentes (RFC)",
    shortName: "RFC",
  },
  MY: {
    type: "tin",
    length: 14,
    fullName: "Nombor Cukai Pendapatan (TIN)",
    shortName: "TIN",
  },
  NL: {
    type: "bsn",
    length: 9,
    fullName: "Burgerservicenummer (BSN)",
    shortName: "BSN",
  },
  PH: {
    type: "tin",
    length: 12,
    fullName: "Taxpayer Identification Number (TIN)",
    shortName: "TIN",
  },
  PT: {
    type: "nif",
    length: 9,
    fullName: "Número de Identificação Fiscal (NIF)",
    shortName: "NIF",
  },
  SE: {
    type: "personnummer",
    length: 10,
    fullName: "Personnummer",
    shortName: "PERSONNUMMER",
  },
  SG: {
    type: "nric",
    length: 9,
    fullName: "National Registration Identity Card (NRIC)",
    shortName: "NRIC",
  },
} as const;

export const taxIdFullName = (countryCode: string | undefined) =>
  countryTaxIds(countryCode)["fullName"];

export const taxIdShortName = (countryCode: string | undefined) =>
  countryTaxIds(countryCode)["shortName"];

export const validateTaxId = (
  countryCode: string | undefined,
  value: string
): boolean => {
  return validatorForCountry(countryCode).validate(value).isValid;
};

export const formatTaxId = (
  countryCode: string | undefined,
  value: string
): string => {
  return validatorForCountry(countryCode).format(value);
};

export const compactTaxId = (
  countryCode: string | undefined,
  value: string
): string => {
  return validatorForCountry(countryCode).compact(value);
};

export const taxIdMaxLength = (countryCode: string | undefined): number => {
  return countryTaxIds(countryCode)["length"];
};

const countryTaxIds = (countryCode: string | undefined): TaxIdDataType => {
  return (
    countryTaxIdData[countryCode || ""] || {
      type: "",
      length: 0,
      fullName: "",
      shortName: "",
    }
  );
};

const validatorForCountry = (countryCode: string | undefined): Validator => {
  if (countryCode == undefined) {
    return emptyValidator();
  }

  const countryConfigs = countryTaxIds(countryCode);

  let validator;

  // Countries with special formatting or validation cases
  if (["CO", "PT", "GB", "BR", "IE", "SE"].includes(countryCode)) {
    return extraTaxIdValidators[countryCode];
  }

  if (
    stdnum.hasOwnProperty(countryCode) &&
    stdnum[countryCode][countryConfigs["type"]] !== undefined
  ) {
    const stdnumElement = stdnum[countryCode];
    validator = stdnumElement[countryConfigs["type"]];
  } else if (extraTaxIdValidators.hasOwnProperty(countryCode)) {
    validator = extraTaxIdValidators[countryCode];
  } else {
    validator = emptyValidator();
  }
  return validator;
};

const extraTaxIdValidators: Record<string, Validator> = {
  BR: brTaxIdValidator,
  PH: phTaxIdValidator,
  MY: myTaxIdValidator,
  SG: sgTaxIdValidator,
  CO: coTaxIdValidator,
  PT: ptTaxIdValidator,
  GB: gbTaxIdValidator,
  IE: ieTaxIdValidator,
  SE: seTaxIdValidator,
};

const emptyValidator = (): Validator => {
  return new (class implements Validator {
    abbreviation: string = "";
    localName: string = "";
    name: string = "";

    compact(): string {
      return "";
    }

    format(): string {
      return "";
    }

    validate(): ValidateReturn {
      return {
        error: new ValidationError("Empty Validator: nothing is valid."),
        isValid: false,
      };
    }
  })();
};
