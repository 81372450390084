import { useTranslation } from "react-i18next";
import CardAction from "../../CardAction/CardAction";
import useNavigateToBankAccountEditing from "pages/employer-of-record/employee-profile/hooks/useNavigateToBankAccountEditing";

export default function EditBankAccountAction() {
  const { t } = useTranslation();
  const navigateToBankAccountEditing = useNavigateToBankAccountEditing();

  return (
    <CardAction
      name={t("Verify to edit")}
      icon="lock"
      onClick={navigateToBankAccountEditing}
    />
  );
}
