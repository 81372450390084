import { Spinner } from "@justworkshr/milo-core";
import { getEnvVariable, VARIABLE_NAMES } from "lib/environment";
import { buildWebPath } from "lib/resource-finder";

export default function SecurityLogIn() {
  const globalIdentitySettingsUrl = new URL(
    getEnvVariable(VARIABLE_NAMES.GLOBAL_IDENTITY_SETTINGS_URL)
  );

  globalIdentitySettingsUrl.searchParams.append(
    "referrer",
    buildWebPath("/account_settings")
  );

  window.location.replace(globalIdentitySettingsUrl.href);

  return <Spinner data-testid="spinner" />;
}
