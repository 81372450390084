import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { getDescription } from "pages/employer-of-record/utils";
import * as SPAIN from "pages/employer-of-record/onboarding/profile-info/form/es/constants.es";
import { array, countryIs, field, personalInfo, REQUIRED } from "./utils";

export const spainFields = {
  esMaritalStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "esMaritalStatus",
    label: "Marital status",
    validations: REQUIRED,
    options: SPAIN.esMaritalStatusOptions,
    scenarios: {
      editProfile: countryIs("ES"),
      viewProfile: countryIs("ES"),
    },
    value: personalInfo("esMaritalStatus", ""),
    format: (value) =>
      getDescription(SPAIN.esMaritalStatusOptions, value) ?? value,
  }),
  esEducationalQualification: field({
    type: FieldTypes.select,
    informationType: "education",
    name: "esEducationalQualification",
    label: "Level of education",
    validations: REQUIRED,
    options: SPAIN.esEducationalQualificationOptions,
    scenarios: {
      editProfile: (profile) => profile.employment?.workCountry === "ES",
      viewProfile: countryIs("ES"),
    },
    value: personalInfo("esEducationalQualification", ""),
    format: (value) =>
      getDescription(SPAIN.esEducationalQualificationOptions, value) ?? value,
  }),
  esChildrensBirthDates: array({
    type: FieldTypes.date,
    informationType: "family",
    array: {
      limit: 10,
    },
    name: "esChildrensBirthDates",
    label: "Children's birth dates",
    scenarios: {
      editProfile: (profile) => profile.employment?.workCountry === "ES",
      viewProfile: (profile) => profile.employment?.workCountry === "ES",
    },
    value: personalInfo("esChildrensBirthDates", []),
  }),
  esSsn: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "esSsn",
    label: "Social Security ID (Número de afiliación)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("ES"),
    },
    value: personalInfo("esSsn", ""),
  }),
  esDni: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "esDni",
    label: "Documento Nacional de Identidad (DNI)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("ES"),
    },
    value: personalInfo("identifierNumber", ""),
    hideWhen: {
      nationality: (nationality) =>
        typeof nationality === "string" && nationality !== "ES",
    },
  }),
  esNie: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "esNie",
    label: "Número de Identificación de Extranjero (NIE)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("ES"),
    },
    value: personalInfo("esNie", ""),
    hideWhen: {
      nationality: (nationality) =>
        typeof nationality === "string" && nationality === "ES",
    },
  }),
};
