import moment from "moment";
import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { NATIONALITIES } from "pages/employer-of-record/onboarding/profile-info/constants";
import { getDescription } from "pages/employer-of-record/utils";
import isEqual from "lodash/isEqual";
import {
  array,
  field,
  yes,
  personalInfo,
  omit,
  isNotNil,
  stripTypename,
  isAdmin,
  countryIs,
  REQUIRED,
} from "./utils";

export const common = {
  firstName: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "firstName",
    label: "First name",
    validations: REQUIRED,
    scenarios: {
      editProfile: yes,
    },
    value: personalInfo("firstName", ""),
  }),
  middleName: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "middleName",
    label: "Middle name",
    scenarios: {
      editProfile: yes,
    },
    value: personalInfo("middleName", ""),
  }),
  lastName: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "lastName",
    label: "Last name",
    validations: REQUIRED,
    scenarios: {
      editProfile: yes,
    },
    value: personalInfo("lastName", ""),
  }),
  dateOfBirth: field({
    type: FieldTypes.date,
    informationType: "identification",
    name: "dateOfBirth",
    label: "Date of birth",
    validations: {
      ...REQUIRED,
      date: {
        max: {
          value: moment().subtract(18, "years").toDate(),
          message: "Must be at least 18 years old",
        },
        min: {
          value: moment("01/01/1921", "MM/DD/YYYY").toDate(),
          message: "Too old!",
        },
      },
    },
    scenarios: {
      editProfile: yes,
    },
    value: personalInfo("dateOfBirth", ""),
  }),
  preferredFirstName: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "preferredFirstName",
    label: "Preferred name",
    message:
      "This is the name you'll see in your company directory and in emails from us.",
    scenarios: {
      editProfile: yes,
    },
    value: personalInfo("preferredFirstName", ""),
  }),
  pronouns: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "pronouns",
    label: "Pronouns",
    message:
      "This will be displayed on your public profile page and in your company org chart.",
    placeholder: "e.g. He/him, She/her, They/them",
    scenarios: {
      editProfile: yes,
    },
    value: personalInfo("pronouns", ""),
  }),
  homeAddress: field({
    type: FieldTypes.address,
    informationType: "address",
    name: "homeAddress",
    label: "Home address",
    scenarios: {
      editProfile: yes,
    },
    value: (profile) => {
      const address = profile.contactDetails?.homeAddress;

      if (address) return omit("type", address);

      return {
        address1: "",
        adddress2: "",
        zoneCode: "",
        city: "",
        countryCode: profile.employment?.workCountry,
        postalCode: "",
      };
    },
  }),
  sameAddress: field({
    type: FieldTypes.checkbox,
    informationType: "address",
    name: "sameAddress",
    label: "Mailing address",
    description: "Same as home address",
    validations: REQUIRED,
    scenarios: {
      editProfile: yes,
    },
    value: (profile) => {
      const { homeAddress, mailingAddress } = profile.contactDetails ?? {};

      if (!homeAddress || !mailingAddress) return false;

      return isEqual(omit("type", homeAddress), omit("type", mailingAddress));
    },
  }),
  mailingAddress: field({
    type: FieldTypes.address,
    informationType: "address",
    name: "mailingAddress",
    label: "",
    scenarios: {
      editProfile: yes,
    },
    value: (profile) => {
      const address = profile.contactDetails?.mailingAddress;

      if (address) return omit("type", address);

      return {
        address1: "",
        adddress2: "",
        zoneCode: "",
        city: "",
        countryCode: profile.employment?.workCountry,
        postalCode: "",
      };
    },
    hideWhen: {
      sameAddress: (value) => !!value,
    },
  }),
  phoneNumbers: array({
    type: FieldTypes.phoneNumber,
    informationType: "contact",
    array: {
      limit: 3,
    },
    name: "phoneNumbers",
    label: "Phone number",
    scenarios: {
      editProfile: yes,
    },
    value: (profile) =>
      [
        profile.contactDetails?.mobilePhoneNumber,
        profile.contactDetails?.homePhoneNumber,
        profile.contactDetails?.workPhoneNumber,
      ]
        .filter(isNotNil)
        .map(stripTypename),
  }),
  workEmail: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "workEmail",
    label: "Work email address",
    validations: {
      ...REQUIRED,
      text: {
        email: {
          enabled: true,
          message: "Must be a valid email address",
        },
      },
    },
    scenarios: {
      editProfile: isAdmin,
    },
    value: (profile) => profile.employment?.workEmail ?? "",
  }),
  homeEmail: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "homeEmail",
    label: "Home email address",
    validations: {
      ...REQUIRED,
      text: {
        email: {
          enabled: true,
          message: "Must be a valid email address",
        },
      },
    },
    scenarios: {
      editProfile: yes,
    },
    value: personalInfo("email", ""),
  }),
  emergencyContactFirstName: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "emergencyContactFirstName",
    label: "First name",
    validations: REQUIRED,
    scenarios: {
      editProfile: yes,
    },
    value: (profile) => profile.personalInfo?.emergencyContact?.firstName ?? "",
  }),
  emergencyContactLastName: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "emergencyContactLastName",
    label: "Last name",
    validations: REQUIRED,
    scenarios: {
      editProfile: yes,
    },
    value: (profile) => profile.personalInfo?.emergencyContact?.lastName ?? "",
  }),
  emergencyContactRelationship: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "emergencyContactRelationship",
    label: "Relationship",
    validations: REQUIRED,
    scenarios: {
      editProfile: yes,
    },
    value: (profile) =>
      profile.personalInfo?.emergencyContact?.relationship ?? "",
  }),
  emergencyContactPhoneNumber: field({
    type: FieldTypes.phoneNumber,
    withoutPhoneNumberType: true,
    informationType: "contact",
    name: "emergencyContactPhoneNumber",
    label: "Phone Number",
    validations: REQUIRED,
    scenarios: {
      editProfile: yes,
    },
    value: (profile) =>
      profile.personalInfo?.emergencyContact?.phoneNumber ?? {
        type: "",
        value: "",
      },
  }),
  workId: field({
    type: FieldTypes.text,
    informationType: "job",
    name: "workId",
    label: "Work ID",
    validations: REQUIRED,
    scenarios: {
      editProfile: isAdmin,
    },
    value: (profile) => profile.jobInformation?.workId ?? "",
  }),
  departmentUuid: field({
    type: FieldTypes.select,
    informationType: "job",
    name: "departmentUuid",
    label: "Department",
    placeholder: "Select Department",
    // These get monkey-patched at render-time with dynamic values from the backend
    options: [],
    scenarios: {
      editProfile: isAdmin,
    },
    value: (profile) => profile.role?.departmentUuid ?? "",
  }),
  managerUuid: field({
    type: FieldTypes.select,
    informationType: "job",
    name: "managerUuid",
    label: "Manager",
    placeholder: "Select Manager",
    // These get monkey-patched at render-time with dynamic values from the backend
    options: [],
    scenarios: {
      editProfile: isAdmin,
    },
    value: (profile) => profile.jobInformation?.managerInfo?.uuid ?? "",
  }),
  nationality: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "nationality",
    label: "Nationality",
    placeholder: "Select Nationality",
    options: NATIONALITIES,
    validations: REQUIRED,
    scenarios: {
      editProfile: (profile) => profile.employment?.workCountry === "PT",
      viewProfile: countryIs(["BR", "CO", "ES", "IE", "IN", "MX", "PT", "PH"]),
      onboarding: countryIs(["BR", "MX", "PH"]),
    },
    value: personalInfo("nationality", ""),
    format: (value) => getDescription(NATIONALITIES, value) ?? value,
  }),
};
