import { DataType } from "pages/employer-of-record/components/AutoForm";
import Row from "../Row/Row";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import { ProfileField } from "pages/employer-of-record/configs";

type Props = ProfileField;

export default function ProfileInfoRow(props: Props) {
  const value = useFormattedValue(props);
  return (
    <Row
      label={props.label ?? props.name}
      required={props.validations?.required.enabled}
    >
      {value}
    </Row>
  );
}

function isEmpty(value: DataType): boolean {
  if (Array.isArray(value) || typeof value === "string") {
    return value.length === 0;
  }

  return false;
}

function useFormattedValue(field: ProfileField) {
  const { profileInfo } = useProfileContextData();
  const valueOrValues = field.value(profileInfo!.eorEmployeeProfile!);

  if (isEmpty(valueOrValues)) return null;

  const formattedValues = [];
  const values = Array.isArray(valueOrValues) ? valueOrValues : [valueOrValues];

  for (const value of values) {
    if (typeof value === "string") {
      const formattedValue = field.format?.(value) ?? value;
      formattedValues.push(<div>{formattedValue}</div>);
    } else if (typeof value === "boolean") {
      const formattedValue = field.format?.(value.toString()) ?? value;
      formattedValues.push(<div>{formattedValue}</div>);
    }
  }

  return formattedValues;
}
