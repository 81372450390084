export const VARIABLE_NAMES = {
  VARIABLE_TO_TEST_WITH: "variableToTestWith",
  FAVICON_COLOR: "faviconColor",
  HEAP_KEY: "heapKey",
  JAZZHR_BASE_URL: "jazzhrBaseUrl",
  MESH_HOST: "meshHost",
  OKTA_CLIENT_ID: "oktaClientId",
  OKTA_ISSUER: "oktaIssuer",
  DROPBOX_SIGN_CLIENT_ID: "dropboxSignClientId",
  GLOBAL_IDENTITY_SETTINGS_URL: "globalIdentitySettingsUrl",
} as const;
