import { InformationType } from "pages/employer-of-record/components/AutoForm";
import { Card } from "@justworkshr/milo-core";
import { ReactElement } from "react";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import EditAction from "../EditAction/EditAction";
import ProfileInfoRow from "./ProfileInfoRow";
import { EorEmployeeProfile } from "types/employer-of-record";
import { PROFILE_FIELDS, ProfileField } from "pages/employer-of-record/configs";

type Props = {
  title: string;
  editable: boolean;
  informationType: InformationType;
  fields: ProfileField[];
};

export function shouldHide(
  field: ProfileField,
  eorEmployeeProfile: EorEmployeeProfile
) {
  for (const entry of Object.entries(field.hideWhen ?? {})) {
    const [name, shouldHide] = entry;
    const field = PROFILE_FIELDS[name as keyof typeof PROFILE_FIELDS];
    if (!field) continue;
    if (shouldHide(field.value(eorEmployeeProfile))) {
      return true;
    }
  }

  return false;
}

export default function ProfileInfoCard(props: Props): ReactElement {
  const { title, informationType, fields, editable } = props;
  const { profileInfo } = useProfileContextData();

  return (
    <>
      <Card
        title={title}
        actions={[<EditAction target={informationType} enabled={editable} />]}
      >
        {fields
          .filter(
            (field) => !shouldHide(field, profileInfo!.eorEmployeeProfile!)
          )
          .map((field) => (
            <ProfileInfoRow key={field.name} {...field} />
          ))}
      </Card>
    </>
  );
}
