import { EorEmployeeProfile } from "types/employer-of-record";
import { FieldTypes, ArrayConfig } from "../../components/AutoForm";
import { ProfileField, ScenarioAccessControlFunction } from "../types";

export const REQUIRED = {
  required: {
    enabled: true,
    message: "This field is required",
  },
};

export function field<FieldType extends FieldTypes>(
  definition: Omit<ProfileField<FieldType, undefined>, "array">
): ProfileField<FieldType, undefined> {
  return {
    ...definition,
  } as ProfileField<FieldType, undefined>;
}

export function array<FieldType extends FieldTypes>(
  definition: ProfileField<FieldType, ArrayConfig>
): ProfileField<FieldType, ArrayConfig> {
  return definition;
}

export function personalInfo<
  K extends keyof NonNullable<EorEmployeeProfile["personalInfo"]>
>(
  key: K,
  defaultValue: NonNullable<NonNullable<EorEmployeeProfile["personalInfo"]>[K]>
) {
  return (profile: EorEmployeeProfile) =>
    profile.personalInfo?.[key] ?? defaultValue;
}

export const omit = <T extends object, K extends keyof T>(
  removeKey: K,
  value: T
): Omit<T, K> =>
  Object.fromEntries(
    Object.entries(value).filter(([key]) => key !== removeKey)
  ) as Omit<T, K>;

export function yes() {
  return true;
}

export function isNotNil<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function stripTypename<T extends { __typename?: string }>(
  thing: T
): Omit<T, "__typename"> {
  return Object.fromEntries(
    Object.entries(thing).filter(([key]) => key !== "__typename")
  ) as Omit<T, "__typename">;
}

export const isAdmin: ScenarioAccessControlFunction = (
  profile,
  authenticatedMember
) => authenticatedMember.uuid !== profile.personalInfo?.memberUuid;

export const isUser: ScenarioAccessControlFunction = (
  profile,
  authenticatedMember
) => !isAdmin(profile, authenticatedMember);

export const countryIs =
  (countryCodeOrCodes: string | string[]) => (profile: EorEmployeeProfile) => {
    const workCountry = profile.employment?.workCountry;
    if (!workCountry) return false;
    const values = Array.isArray(countryCodeOrCodes)
      ? countryCodeOrCodes
      : [countryCodeOrCodes];
    return values.includes(workCountry);
  };
