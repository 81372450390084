import { useState, ReactElement } from "react";
import { Alert, Button, ButtonType, ButtonProps } from "@justworkshr/milo-core";
import styles from "pages/company-settings/components/InitiateMicrodepositPage.module.css";
import EditBankAccountForm from "pages/company-settings/components/EditBankAccountForm";
import { useParams } from "react-router-dom";
import VerificationErrorNotice from "./VerificationErrorNotice";
import {
  BankFormData,
  useBankForm,
} from "pages/company-settings/hooks/useBankForm";
import BankAccountPageHeader from "./BankAccountPageHeader";
import {
  ProcessedBankResponseError,
  useSubmitBankAccount,
} from "../hooks/useSubmitBankAccount";
import { ActionFooter, CheckboxInput } from "@justworkshr/milo-form";
import TermsAndConditionsCopy from "./TermsAndConditionsCopy";
import usePlaidBankAccountFlow from "../hooks/usePlaidBankAccountFlow";

const {
  page,
  pageHeader,
  formContainer,
  amountDetails,
  detailsHeader,
  content,
} = styles;

export default function EditBankAccountPage() {
  const { errorType } = useParams();
  const {
    acceptTerms,
    acceptedTerms,
    showGlobalNetworkError,
    isOnboarding,
    isLoadingOnboardingCheck,
    isOnReverseWire,
  } = useBankForm();

  const {
    openPlaidModal,
    isPending: isPlaidPending,
    isSuccess: isPlaidSuccess,
    plaidError,
    plaidAccountCreateError,
  } = usePlaidBankAccountFlow("link");

  const { mutate, error, isPending, isSuccess } = useSubmitBankAccount();

  const [checkedTerms, setCheckedTerms] = useState(false);

  const bankResponseError = error as ProcessedBankResponseError | null;

  const isPayrollInProgessError =
    bankResponseError && bankResponseError.body.error === "payroll_in_progress";

  const handleSubmit = async (formData: BankFormData) => {
    return mutate(formData);
  };

  const renderErrorContent = () => {
    if (errorType) {
      return <VerificationErrorNotice errorType={errorType} />;
    }
  };

  if (isLoadingOnboardingCheck) {
    return <div />;
  }

  const formInstructions = isOnboarding
    ? "Enter the business bank account information you wish to use to process your payroll."
    : "Enter the new business account information you wish to use to replace your current one.";

  const getFooterActionButtons = (): ReactElement<
    ButtonProps<ButtonType>
  >[] => {
    const buttons = [
      <Button
        key="manual-connect-button"
        data-testid="manual-connect-button"
        type="submit"
        disabled={!checkedTerms}
        variant={isOnReverseWire ? "filled" : "ghost"}
        onClick={() => {
          window.scrollTo(0, 0);
          acceptTerms();
        }}
      >
        Connect Manually
      </Button>,
    ];

    if (!isOnReverseWire) {
      buttons.push(
        <Button
          key="plaid-connect-button"
          data-testid="plaid-connect-button"
          type="submit"
          loading={isPlaidPending || isPlaidSuccess}
          disabled={!checkedTerms}
          onClick={openPlaidModal}
        >
          Connect using Plaid
        </Button>
      );
    }
    return buttons;
  };

  return (
    <div>
      {!!(
        showGlobalNetworkError ||
        (bankResponseError && !isPayrollInProgessError)
      ) && (
        <Alert color="destructive">
          Something went wrong. Please try submitting again.
        </Alert>
      )}
      {!!(bankResponseError && isPayrollInProgessError) && (
        <Alert color="destructive">
          Payroll is running. Come back after 6 pm today to update your bank
          account.
        </Alert>
      )}
      {(plaidError || plaidAccountCreateError) && !acceptedTerms && (
        <Alert color="destructive">
          Something went wrong. We’re not able to connect your account via
          Plaid, please try connecting manually.
        </Alert>
      )}
      <div
        className={page}
        data-testid="edit-bank-account-with-confirmation-page"
      >
        <div className={pageHeader}>
          <BankAccountPageHeader>
            Add the details for the new business bank account that you’d like to
            use. We securely verify your account information with GIACT, a
            trusted security industry leader.
          </BankAccountPageHeader>
          <div className={content}>
            {renderErrorContent()}
            {!acceptedTerms && (
              <>
                <TermsAndConditionsCopy />
                <CheckboxInput
                  label="By checking this box, I agree to the Justworks ACH and Account Authorization Agreement"
                  name="terms"
                  checked={checkedTerms}
                  value="accept"
                  onChange={() => setCheckedTerms(!checkedTerms)}
                />
                <br />
                <ActionFooter actions={getFooterActionButtons()} />
              </>
            )}
            {acceptedTerms && (
              <>
                <h2 className={detailsHeader}>New account details</h2>
                <p className={amountDetails}>{formInstructions}</p>
                <div className={formContainer}>
                  <EditBankAccountForm
                    onSubmit={handleSubmit}
                    loading={isPending || isSuccess}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
