import { ReactElement, useEffect } from "react";
import OverviewImage from "../../images/overview-banner.webp";
import styles from "../../InternationalSolutionsPage.module.css";
import {
  CUSTOMER_IO_PAGEVIEW_EVENT,
  sendCustomerIOEvent,
} from "app/customer-io";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import { useGetCompanyDisplayQuery } from "types/generated/operations";
import InternationalSolutionsCard from "pages/international-solutions/components/Cards/InternationalSolutionsCard";
import ResourcesCard from "pages/international-solutions/components/Cards/ResourcesCard";

const Overview = (): ReactElement => {
  const { container, column1, column2, divWrapper, lgHead, lgGap, mdFont } =
    styles;

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();
  useEffect(() => {
    // Wait memberData to be available to avoid redundant calls
    if (!memberData?.authenticatedMember) return;

    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_PAGEVIEW_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: { page_url: window.location.href },
      },
      accessToken
    );
  }, [accessToken, memberData]);

  return (
    <div className={container}>
      <div className={column1}>
        <img src={OverviewImage} alt="" />
        <div className={`${divWrapper} ${lgGap}`}>
          <div className={lgHead}>
            Global hiring made simple for your small business
          </div>
          <div className={mdFont}>
            Hiring globally unlocks new opportunities and expands your company’s
            potential. Let us be the trusted partner that helps you grow,
            innovate, and thrive on a global scale with our time and
            money-saving all-in-one platform.
          </div>
          <div className={lgHead}>Scale Your Team Internationally</div>
          <div>
            <InternationalSolutionsCard intlSolutionType="eor" />
          </div>
          <div>
            <InternationalSolutionsCard intlSolutionType="intlContractors" />
          </div>
        </div>
      </div>
      <div className={`${column2}`}>
        <ResourcesCard />
      </div>
    </div>
  );
};

export default Overview;
