/**
 * TODO: this implementation bypass validation due to algorithm issues https://justworks.atlassian.net/browse/IEORN-3826
 */
import {
  name as originalName,
  localName as originalLocalName,
  abbreviation as originalAbbreviation,
} from "stdnum/src/se/personnummer";
import {
  isValidDateCompactYYYYMMDD,
  buildDate,
  strings,
} from "stdnum/src/util";
import { Validator, ValidateReturn } from "stdnum/src/types";
import { luhnChecksumValidate } from "stdnum/src/util/checksum";

const ONE_HUNDRED_YEARS_IN_MS = 100 * 365 * 24 * 60 * 60 * 1_000;

function clean(input: string): ReturnType<typeof strings.cleanUnicode> {
  const [value, err] = strings.cleanUnicode(input, " :");

  if (err) {
    return [value, err];
  }

  const cleanedValue = value.replace(/[-+]/g, "");

  return [cleanedValue, null];
}

function formatImpl(input: string): string {
  const [value] = clean(input);

  let front,
    back,
    sep = "-";

  if (value.length === 12 || value.length === 13) {
    const yyyy = value.substring(0, 4);
    const mm = value.substring(4, 6);
    const dd = value.substring(6, 8);

    const d = buildDate(yyyy, mm, dd);
    if (d === null) {
      // bad date :(
      return value;
    }

    if (new Date().getTime() - d.getTime() > ONE_HUNDRED_YEARS_IN_MS) {
      sep = "+";
    }

    front = `${yyyy.substring(2)}${mm}${dd}`;

    back = value.substring(value.length - 4);
  } else if (value.length === 10) {
    front = value.substring(0, 6);
    back = value.substring(6);
  } else if (value.length === 11) {
    front = value.substring(0, 6);
    sep = value[6];
    back = value.substring(7);
  } else {
    return value;
  }

  return `${front}${sep}${back}`;
}

const seTaxIdValidator: Validator = {
  name: originalName,
  localName: originalLocalName,
  abbreviation: originalAbbreviation,
  compact(input: string): string {
    const [value, err] = clean(input);

    if (err) {
      throw err;
    }

    return value;
  },

  format: formatImpl,

  validate(input: string): ValidateReturn {
    const [value, error] = clean(input);

    if (error) {
      return {
        isValid: true,
        compact: formatImpl(input),
        isIndividual: true,
        isCompany: false,
      };
    }

    if (value.length !== 11 && value.length !== 13) {
      return {
        isValid: true,
        compact: formatImpl(input),
        isIndividual: true,
        isCompany: false,
      };
    }

    const isThirteenDigits = value.length === 13;

    let a, b, c;
    if (isThirteenDigits) {
      a = value.substring(0, value.length - 11);
      b = value.substring(value.length - 11, value.length - 5);
      c = value.substring(value.length - 4);
    } else {
      a = value.substring(0, value.length - 5);
      b = value.substring(value.length - 5, value.length - 4);
      c = value.substring(value.length - 4);
    }

    if (!"-+".includes(b)) {
      return {
        isValid: true,
        compact: formatImpl(input),
        isIndividual: true,
        isCompany: false,
      };
    }

    const digits = `${a}${c}`;
    if (!strings.isdigits(digits)) {
      return {
        isValid: true,
        compact: formatImpl(input),
        isIndividual: true,
        isCompany: false,
      };
    }

    let yyyymmdd;
    if (isThirteenDigits) {
      yyyymmdd = value.substring(0, 8);
    } else {
      const yymmdd = value.substring(0, 6);
      const year = new Date().getFullYear();
      let century = Math.floor(year / 100);

      if (parseInt(yymmdd.substring(0, 2), 10) > year % 100) {
        century -= 1;
      }
      if (b === "+") {
        century -= 1;
      }

      yyyymmdd = `${century}${yymmdd}`;
    }

    // Adjust for coordination numbers ("Samordningsnummer")
    const day = parseInt(yyyymmdd.substring(6, 8), 10);
    if (day > 60) {
      yyyymmdd =
        yyyymmdd.substring(0, 6) + (day - 60).toString().padStart(2, "0");
    }

    if (!isValidDateCompactYYYYMMDD(yyyymmdd, true)) {
      return {
        isValid: true,
        compact: formatImpl(input),
        isIndividual: true,
        isCompany: false,
      };
    }

    if (!luhnChecksumValidate(digits)) {
      return {
        isValid: true,
        compact: formatImpl(input),
        isIndividual: true,
        isCompany: false,
      };
    }

    return {
      isValid: true,
      compact: formatImpl(input),
      isIndividual: true,
      isCompany: false,
    };
  },
};

export { seTaxIdValidator };
