import { ReactElement, ReactNode, useState } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box, Button } from "@justworkshr/milo-core";
import {
  CF_ADD_CONTRACTOR_LINK,
  CF_ADD_EMPLOYEES_LINK,
} from "pages/international-solutions/constants";
import { useNavigate } from "react-router-dom";
import {
  CONTRACTORS_PATH,
  EMPLOYEES_EOR_PATH,
} from "pages/international-solutions/routes";
import ThankYouDialog from "../Dialog/ThankYouDialog";
import {
  useGetCompanyDisplayQuery,
  useGetIsEmployerOfRecordEnabledQuery,
} from "types/generated/operations";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import {
  sendCustomerIOEvent,
  CUSTOMER_IO_CTA_CLICK_EVENT,
} from "app/customer-io";
import { SystemIcon } from "@justworkshr/milo-icons";

export interface InternationalSolutionsCardProps {
  intlSolutionType: string;
}
const InternationalSolutionsCard = ({
  intlSolutionType,
}: InternationalSolutionsCardProps): ReactElement => {
  const {
    divWrapper,
    smHead,
    hr,
    lgHead,
    mdFont,
    lgGap,
    countryList,
    countryBox,
    moreCountriesBox,
  } = styles;

  const navigate = useNavigate();

  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const handleThankYouDialog = () => {
    setShowThankYouDialog((prev) => !prev);
  };

  const { data: eorEnabledData, loading } =
    useGetIsEmployerOfRecordEnabledQuery();
  const isEmployerOfRecordEnabled =
    eorEnabledData?.authenticatedMember.company.companySetting?.value ===
      "true" && flagIsEnabled(FLAG_NAMES.EMPLOYER_OF_RECORD);

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();

  const triggerCustomerIOEvent = (ctaName: string, nextUrl: string) => {
    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_CTA_CLICK_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: {
          current_url: window.location.href,
          cta_name: ctaName,
          next_url: nextUrl,
        },
      },
      accessToken
    );
  };

  type CountryFlagMap = Record<string, string>;
  const countryFlagMap: CountryFlagMap = {
    Australia: "🇦🇺",
    India: "🇮🇳",
    Ireland: "🇮🇪",
    Portugal: "🇵🇹",
    Netherlands: "🇳🇱",
    Spain: "🇪🇸",
    "United Kingdom": "🇬🇧",
    Colombia: "🇨🇴",
    Philippines: "🇵🇭",
    Canada: "🇨🇦",
    Mexico: "🇲🇽",
    Brazil: "🇧🇷",
    Germany: "🇩🇪",
    France: "🇫🇷",
    Argentina: "🇦🇷",
    Austria: "🇦🇹",
  };
  interface Content {
    title: string;
    description: string;
    countryDescription: string;
    countries: Array<string>;
    moreCountries?: ReactNode;
  }
  interface CardContent {
    [key: string]: Content;
  }
  const cardContent: CardContent = {
    eor: {
      title: "Employer of Record (EOR) for full-time employment worldwide",
      description:
        "Justworks simplifies your global hiring needs with our Employer of Record (EOR) solution, handling the legal responsibilities of hiring full-time employees internationally. Our EOR services are available in multiple countries (listed below), with more being added to expand your global access!",
      countryDescription: "EOR country coverage",
      countries: [
        "Australia",
        "Canada",
        "India",
        "Ireland",
        "Mexico",
        "Netherlands",
        "Portugal",
        "Spain",
        "United Kingdom",
        "Brazil",
        "Colombia",
        "Philippines",
      ],
    },
    intlContractors: {
      title: "International contractor payments to kickstart global growth",
      description:
        "Our International Contractor Payments make it easy to manage and pay contractors globally, all within the same platform you trust for your domestic team. With new countries added frequently, you can easily support contractors wherever they’re based!",
      countryDescription: "International contractors country coverage",
      countries: [
        "Australia",
        "Canada",
        "India",
        "Germany",
        "France",
        "Mexico",
        "Philippines",
        "Portugal",
        "Spain",
        "United Kingdom",
        "Argentina",
        "Austria",
      ],
      moreCountries: (
        <>
          and{" "}
          <a
            href="https://help.justworks.com/hc/en-us/articles/18040350606619-International-Contractors"
            target="_blank"
          >
            21 more{" "}
            <SystemIcon iconName="external-link" color="brand" size="small" />
          </a>
        </>
      ),
    },
  };
  const { title, description, countryDescription, countries, moreCountries } =
    cardContent[intlSolutionType] || {};

  const renderButton = () => {
    switch (intlSolutionType) {
      case "eor":
        return (
          <div className={divWrapper}>
            <Button
              data-heap-id={
                isEmployerOfRecordEnabled
                  ? "eor-add-employees-button"
                  : "no-eor-im-interested-button"
              }
              color="brand"
              type="button"
              size="md"
              as={isEmployerOfRecordEnabled ? "a" : undefined}
              href={
                isEmployerOfRecordEnabled ? CF_ADD_EMPLOYEES_LINK : undefined
              }
              onClick={() => {
                if (!isEmployerOfRecordEnabled) {
                  triggerCustomerIOEvent(
                    "eor_overview_im_interested_button",
                    "thank-you-modal"
                  );
                  handleThankYouDialog();
                } else {
                  triggerCustomerIOEvent(
                    "eor_overview_add_employee_button",
                    CF_ADD_EMPLOYEES_LINK
                  );
                }
              }}
              loading={loading}
            >
              Get started
            </Button>
            <Button
              color="brand"
              size="md"
              type="button"
              variant="ghost"
              onClick={() => {
                navigate(`..${EMPLOYEES_EOR_PATH}`);
              }}
            >
              Learn more
            </Button>
          </div>
        );
      case "intlContractors":
        return (
          <div className={divWrapper}>
            <Button
              data-heap-id="add-contractor-button"
              color="brand"
              type="button"
              as="a"
              size="md"
              href={CF_ADD_CONTRACTOR_LINK}
              onClick={() => {
                triggerCustomerIOEvent(
                  "eor_overview_add_contractor_button",
                  CF_ADD_CONTRACTOR_LINK
                );
              }}
            >
              Add Contracts
            </Button>
            <Button
              color="brand"
              size="md"
              type="button"
              variant="ghost"
              onClick={() => {
                navigate(`..${CONTRACTORS_PATH}`);
              }}
            >
              Learn more
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="xl"
    >
      <div className={`${divWrapper} ${lgGap}`}>
        <div className={lgHead}>{title}</div>
        <div className={mdFont}>{description}</div>

        <hr className={hr}></hr>

        <div className={smHead}>{countryDescription}</div>
        <div className={`${countryList}`}>
          {countries.map((country, index) => (
            <div key={index} className={`${countryBox} `}>
              <span style={{ marginRight: "10px" }}>
                {countryFlagMap[country] || ""}{" "}
              </span>
              <p>{country}</p>
            </div>
          ))}
          <div className={`${moreCountriesBox} ${mdFont}`}>
            <p>{moreCountries}</p>
          </div>
        </div>

        {renderButton()}

        {showThankYouDialog && (
          <ThankYouDialog
            isOpen={showThankYouDialog}
            onClose={handleThankYouDialog}
          />
        )}
      </div>
    </Box>
  );
};

export default InternationalSolutionsCard;
