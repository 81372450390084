import { ReactElement } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box, Button } from "@justworkshr/milo-core";
import {
  CF_ADD_CONTRACTOR_LINK,
  CONTRACTOR_PRICE,
  INTERNATIONAL_CONTRACTORS_PRODUCT_HELP_LINK,
} from "pages/international-solutions/constants";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import { useGetCompanyDisplayQuery } from "types/generated/operations";
import {
  CUSTOMER_IO_CTA_CLICK_EVENT,
  sendCustomerIOEvent,
} from "app/customer-io";

const ContractorsPriceCard = (): ReactElement => {
  const { divWrapper, mdGap, mdPadding, mdHead, xsPadding, smFont, hr, smGap } =
    styles;

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();

  const triggerCustomerIOEvent = (ctaName: string, nextUrl: string) => {
    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_CTA_CLICK_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: {
          current_url: window.location.href,
          cta_name: ctaName,
          next_url: nextUrl,
        },
      },
      accessToken
    );
  };

  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="md"
    >
      <div className={`${divWrapper} ${mdGap}`}>
        <div className={`${divWrapper} ${xsPadding} ${smGap}`}>
          <div className={mdHead}>Company Cost</div>
          <div className={smFont}>
            Fees only apply for contractors paid during the month.
          </div>
        </div>
        <hr className={hr}></hr>
        <div className={`${divWrapper} ${mdPadding} ${smGap}`}>
          <div className={mdHead}>Pricing</div>
          <div className={smFont}>{CONTRACTOR_PRICE}</div>
        </div>
        <hr className={hr}></hr>
        <div className={`${divWrapper} ${mdPadding} ${smGap}`}>
          <div className={mdHead}>Country Coverage </div>
          <div className={smFont}>
            50+ countries including Argentina, India, Italy, Germany, and
            Portugal.{" "}
            <a
              href={INTERNATIONAL_CONTRACTORS_PRODUCT_HELP_LINK}
              target="_blank"
            >
              <span className={smFont}>See entire list.</span>
            </a>
          </div>
        </div>
        <Button
          data-heap-id="add-contractor-button"
          color="brand"
          type="button"
          as="a"
          size="md"
          href={CF_ADD_CONTRACTOR_LINK}
          onClick={() =>
            triggerCustomerIOEvent(
              "eor_contractors_add_contractor_button",
              CF_ADD_CONTRACTOR_LINK
            )
          }
        >
          Add contractor
        </Button>
      </div>
    </Box>
  );
};

export default ContractorsPriceCard;
