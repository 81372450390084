import ProfileInfoCO from "./form/co/ProfileInfoCO";
import ProfileInfoES from "./form/es/ProfileInfoES";
import ProfileInfoIE from "./form/ie/ProfileInfoIE";
import ProfileInfoIN from "./form/in/ProfileInfoIN";
import ProfileInfoNL from "./form/nl/ProfileInfoNL";
import ProfileInfoGeneric from "./form/generic/ProfileInfoGeneric";
import ProfileInfoPT from "./form/pt/ProfileInfoPT";
import ProfileInfoSG from "./form/sg/ProfileInfoSG";
import ProfileInfoSE from "./form/se/ProfileInfoSE";

// unify profile info return types - JSX.Element || FC
const CUSTOM_COUNTRY = {
  AU: ProfileInfoGeneric,
  BR: ProfileInfoGeneric,
  CO: ProfileInfoCO,
  ES: ProfileInfoES,
  IE: ProfileInfoIE,
  IN: ProfileInfoIN,
  MX: ProfileInfoGeneric,
  NL: ProfileInfoNL,
  PH: ProfileInfoGeneric,
  PT: ProfileInfoPT,
  SG: ProfileInfoSG,
  SE: ProfileInfoSE,
} as const;

export default CUSTOM_COUNTRY;
