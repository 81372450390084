const TITLE_OPTIONS = [
  {
    value: "mr",
    description: "Mr",
  },
  {
    value: "ms",
    description: "Ms",
  },
  {
    value: "mrs",
    description: "Mrs",
  },
  {
    value: "miss",
    description: "Miss",
  },
  {
    value: "dr",
    description: "Dr",
  },
];

const GENDER_OPTIONS = [
  {
    value: "male",
    description: "Male",
  },
  {
    value: "female",
    description: "Female",
  },
];

export { TITLE_OPTIONS, GENDER_OPTIONS };
