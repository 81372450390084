import { Button, Box } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Dropdown } from "pages/employer-of-record/components";
import { useEffect, useState } from "react";
import styles from "./HeaderActions.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { ViewType } from "app-frame/header/context/view-context-provider";
import { useTranslation } from "react-i18next";

function RequestTerminationButton({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <Button
      as="button"
      variant="outlined"
      type="button"
      size="sm"
      leftIcon="close"
      id="requestTermProfile"
      data-testid="requestTerminationButton"
      onClick={onClick}
    >
      {t("Request separation")}
    </Button>
  );
}

function useNavigateToEditCompensation() {
  const navigate = useNavigate();
  const { id } = useParams();

  return () => {
    navigate(`/employer-of-record/employee-profile/${id}/compensation`);
  };
}

function EditCompensationButton() {
  const { t } = useTranslation();
  const navigateToEditCompensation = useNavigateToEditCompensation();

  return (
    <Button
      as="button"
      variant="outlined"
      size="sm"
      data-testid="editCompensationButton"
      onClick={navigateToEditCompensation}
    >
      {t("Edit compensation")}
    </Button>
  );
}

type DropdownAction = { name: string; onClick?: () => void };
function HeaderActionsDropdown({ actions }: { actions: DropdownAction[] }) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  function openDropdown() {
    setDropdownOpen(true);
  }

  function closeDropdown() {
    setDropdownOpen(false);
  }

  return (
    <Dropdown
      dropdownName="header-mobile-actions"
      contentClassName={styles.dropdownContent}
      onClickOutside={closeDropdown}
      onOpenChange={openDropdown}
      content={
        <Box
          padding="xs"
          border={{
            borderWidth: "md",
            borderColor: "brand",
            borderRadius: "md",
          }}
        >
          {actions.map((action) => (
            <div
              key={action.name}
              className={styles.mobileAction}
              onClick={() => {
                closeDropdown();
                action.onClick?.();
              }}
            >
              {action.name}
            </div>
          ))}
        </Box>
      }
      open={dropdownOpen}
    >
      <SystemIcon iconName="ellipsis" color="brand" className="" />
    </Dropdown>
  );
}

function MobileHeaderActions({
  onRequestTermination,
}: {
  onRequestTermination: () => void;
}) {
  const { t } = useTranslation();
  const navigateToEditCompensation = useNavigateToEditCompensation();

  const actions: DropdownAction[] = [
    {
      name: t("Edit compensation"),
      onClick: navigateToEditCompensation,
    },
    {
      name: t("Request separation"),
      onClick: onRequestTermination,
    },
  ];

  return <HeaderActionsDropdown actions={actions} />;
}

function DesktopHeaderActions({
  onRequestTermination,
}: {
  onRequestTermination: () => void;
}) {
  return (
    <div className={styles.container}>
      <EditCompensationButton />
      <RequestTerminationButton onClick={onRequestTermination} />
    </div>
  );
}

export default function HeaderActions({
  onRequestTermination,
}: {
  onRequestTermination: () => void;
}) {
  function getLayout() {
    return window.innerWidth >= 769 ? ViewType.DESKTOP : ViewType.MOBILE;
  }

  const [layout, setLayout] = useState<ViewType.DESKTOP | ViewType.MOBILE>(
    getLayout()
  );

  useEffect(() => {
    function handleResize() {
      setLayout(getLayout());
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Component =
    layout === ViewType.DESKTOP ? DesktopHeaderActions : MobileHeaderActions;

  return <Component onRequestTermination={onRequestTermination} />;
}
