import { Button, Divider } from "@justworkshr/milo-core";
import styles from "./EditProfile.module.css";
import { useState } from "react";
import { ActionFooter } from "@justworkshr/milo-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  EmergencyContactInput,
  EorEmployeeProfileInput,
  GetEoREmployeeProfileQuery,
  InternationalAddressInput,
  PhoneNumberInput,
} from "types/generated/operations";
import { Form } from "@justworkshr/milo-form";
import { Formik } from "formik";
import { PHONE_TYPES } from "pages/employer-of-record/onboarding/contact-info/PhoneNumbersFragment";
import matches from "lodash/matches";
import moment from "moment";
import { useApolloClient } from "@apollo/client";
import CancelEditModal from "./components/CancelEditModal";
import {
  AutoFormField,
  FieldDataType,
  generateFormValidations,
  INFORMATION_TYPES,
  InformationType,
} from "pages/employer-of-record/components/AutoForm";
import CollapsibleCard from "./components/CollapsibleCard";
import groupBy from "lodash/groupBy";
import omit from "lodash/omit";
import mapValues from "lodash/mapValues";
import { ProfileField } from "pages/employer-of-record/configs/types";
import {
  initialValues,
  useFields,
  PROFILE_FIELDS,
  ProfileFieldData,
  ProfileFieldName,
} from "pages/employer-of-record/configs";

const divideAfter = [
  PROFILE_FIELDS.dateOfBirth.name,
  PROFILE_FIELDS.pronouns.name,
  PROFILE_FIELDS.homeEmail.name,
  PROFILE_FIELDS.homeAddress.name,
];

const subtitlesBefore = {
  [PROFILE_FIELDS.emergencyContactFirstName.name]: "Emergency contact",
};

const cardTitles: Record<InformationType, string> = {
  identification: "Identification",
  address: "Home & mailing address",
  benefits: "Benefits",
  cartao: "Cartão de Cidadão",
  contact: "Contact Information",
  contract: "Contract details",
  education: "Education",
  family: "Family",
  job: "Job Information",
  pension: "Pensions",
  rg: "Carteira de identidade Nacional",
};

type Props = {
  profile: GetEoREmployeeProfileQuery["eorEmployeeProfile"];
  onSubmit: (values: EorEmployeeProfileInput) => void;
  onError: (message: string) => void;
  clearError: () => void;
  linkToProfile: string;
};

function encodeParams(queryParams: Record<string, string | boolean | number>) {
  return Object.entries(queryParams).map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  );
}

function EditableProfile({
  profile,
  onSubmit,
  onError,
  clearError,
  linkToProfile,
}: Props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [open, setOpen] = useState(
    Object.fromEntries(
      INFORMATION_TYPES.map((informationType) => [
        informationType,
        window.location.hash === `#${informationType}`,
      ])
    ) as Record<InformationType, boolean>
  );
  const isAdmin = !!id;
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const fields = useFields({ scenario: "editProfile" });
  const groupedFields: Partial<Record<InformationType, ProfileField[]>> =
    groupBy(fields, ({ informationType }) => informationType);

  const workCountry = profile.employment?.workCountry ?? undefined;

  if (!workCountry) {
    return <div>Something went wrong</div>;
  }

  function toggleCollapsibleSection(sectionName: keyof typeof open) {
    return () =>
      setOpen({
        ...open,
        [sectionName]: !open[sectionName],
      });
  }

  function navigateToProfile(queryParams?: Parameters<typeof encodeParams>[0]) {
    const path =
      linkToProfile + (queryParams ? `?${encodeParams(queryParams)}` : "");
    // force cache to refresh user nav with updated information if name was changed
    if (!isAdmin) {
      client.refetchQueries({ include: ["GetUserMenu"] });
    }
    navigate(path);
  }

  function handleCancelClick() {
    setIsModalOpen(true); // Open the modal
  }

  function handleModalClose() {
    setIsModalOpen(false); // Close the modal
  }

  async function onFormSubmit(values: Partial<ProfileFieldData>) {
    clearError();

    const informationTypeGroupedFields = groupBy(
      fields,
      ({ informationType }) => informationType
    );

    const dynamicValues = mapValues(informationTypeGroupedFields, (fields) =>
      Object.fromEntries(
        fields.map(({ name }) => [
          name,
          (values as Record<string, unknown>)[name],
        ])
      )
    ) as Record<
      NonNullable<FieldDataType["informationType"]>,
      Record<string, unknown>
    >;

    // For each of the dynamic values...if it is a redacted sensitive value...don't send it.
    for (const [groupName, groupValues] of Object.entries(dynamicValues)) {
      for (const [name, value] of Object.entries(groupValues)) {
        if (typeof value === "string" && value.includes("•")) {
          delete dynamicValues[groupName as InformationType][name];
        }
      }
    }

    const homeAddress = omit(values.homeAddress, [
      "__typename",
      "type",
    ]) as InternationalAddressInput;

    const mailingAddress = omit(values.mailingAddress, [
      "__typename",
      "type",
    ]) as InternationalAddressInput;

    try {
      await onSubmit({
        personalInfo: {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          preferredFirstName: values.preferredFirstName,
          pronouns: values.pronouns,
          dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
          emergencyContact: {
            firstName: values.emergencyContactFirstName,
            lastName: values.emergencyContactLastName,
            relationship: values.emergencyContactRelationship,
            phoneNumber: values.emergencyContactPhoneNumber?.value,
          } as EmergencyContactInput,
          email: values.homeEmail,
          ...dynamicValues.cartao,
          ...dynamicValues.education,
          ...dynamicValues.family,
          ...dynamicValues.identification,
          ...dynamicValues.contract,
        },
        contactDetails: {
          homePhoneNumber: values.phoneNumbers?.find(
            matches({ type: PHONE_TYPES.home })
          ) as PhoneNumberInput,
          workPhoneNumber: values.phoneNumbers?.find(
            matches({ type: PHONE_TYPES.work })
          ) as PhoneNumberInput,
          mobilePhoneNumber: values.phoneNumbers?.find(
            matches({ type: PHONE_TYPES.mobile })
          ) as PhoneNumberInput,
          homeAddress: homeAddress,
          mailingAddress: values.sameAddress ? homeAddress : mailingAddress,
        },
        ...(isAdmin && {
          jobInformation: {
            managerUuid: values.managerUuid,
            departmentUuid: values.departmentUuid,
            workId: values.workId,
          },
        }),
        employment: {
          ...(isAdmin && { workEmail: values.workEmail }),
        },
      });

      const preferredName = values.preferredFirstName || undefined;
      const memberName = preferredName ?? values.firstName ?? "the member";

      const updatedAlertText = `The changes you made to ${
        isAdmin ? `${memberName}’s` : "your"
      } profile have been saved.`;

      navigateToProfile({ updated: updatedAlertText });
    } catch {
      onError(t("An unknown error occurred. Please try again later."));
    }
  }

  const formSchema = generateFormValidations(fields, t);
  const sectionOrdering: InformationType[] = [
    "identification",
    "family",
    "education",
    "address",
    "contact", //TODO: Same address
    "job",
    "contract",
    "cartao",
  ];

  return (
    <>
      {isModalOpen && (
        <CancelEditModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          navigateBack={() => navigate(linkToProfile)}
        />
      )}
      <Formik
        initialValues={initialValues(fields, profile)}
        onSubmit={onFormSubmit}
        validationSchema={formSchema}
      >
        {(formikProps) => {
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <div className={styles.collapsesContainer}>
                {sectionOrdering.map((section) => {
                  const fields = groupedFields[section];
                  if (!fields?.length) return null;
                  return (
                    <EditProfileSection
                      title={cardTitles[section]}
                      fields={fields}
                      open={open[section]}
                      onClick={toggleCollapsibleSection(section)}
                      fieldStyles={{
                        dateOfBirth: styles.dateOfBirth,
                        sameAddress: styles.sameAddress,
                      }}
                    />
                  );
                })}

                <ActionFooter
                  actions={
                    <>
                      <Button
                        onClick={handleCancelClick}
                        disabled={formikProps.isSubmitting}
                        color="brand"
                        variant="ghost"
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        disabled={formikProps.isSubmitting}
                        loading={formikProps.isSubmitting}
                        type="submit"
                      >
                        {t("Update")}
                      </Button>
                    </>
                  }
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

function EditProfileSection({
  title,
  fields,
  open,
  onClick,
  fieldStyles,
}: {
  title: string;
  fields: Array<ProfileField>;
  open: boolean;
  onClick: () => void;
  fieldStyles?: Partial<Record<ProfileFieldName, string>>;
}) {
  return (
    <CollapsibleCard open={open} title={title} onClick={onClick}>
      {fields.map((field, index) => {
        let component = <AutoFormField key={field.name} {...field} />;

        if (fieldStyles?.[field.name as ProfileFieldName]) {
          component = <div className={styles[field.name]}>{component}</div>;
        }

        if (subtitlesBefore[field.name]) {
          component = (
            <>
              <div className={styles.title}>{subtitlesBefore[field.name]}</div>
              {component}
            </>
          );
        }

        if (divideAfter.includes(field.name) && index !== fields.length - 1) {
          return (
            <>
              {component}
              <Divider />
            </>
          );
        }

        return component;
      })}
    </CollapsibleCard>
  );
}

export default EditableProfile;
