import { BenefitsAndAllowancesType } from "pages/employer-of-record/payments/constants";
import { COMPONENTLIST } from "../../../SetupFormGenerator/constants";
import { FieldConfig } from "../../../SetupFormGenerator/types";
import { TaxableBenefitsPaymentSettings } from "../../types";

export enum TaxableBenefits {
  CAR = "Car",
  MEDICAL_INSURANCE = "Medical insurance",
  LOANS = "Loans",
  LIVING_ACCOMMODATION = "Living accommodation",
  MEAL_ALLOWANCE = "Meal allowance",
  TRAVEL_ALLOWANCE = "Travel allowance",
  EDUCATION = "Education",
  NONCASH_GIFT_AND_AWARDS = "Non-cash gifts & awards",
  RECREATIONAL_FACILITIES_AND_DUES = "Recreational facilities & dues",
}

export const TAXABLEBENFITSVALUES = Object.values(TaxableBenefits);

export const TaxableBenefitsTypeMessage = null;

export const PAYMENT_SETTINGS_FIELDS: FieldConfig[] = [
  {
    label: "Form of income",
    name: "formOfIncome",
    type: COMPONENTLIST.RADIO,
    options: [
      {
        value: "non-cash",
        label: "Non-cash",
        description:
          "Record a benefit the company paid for outside of the Justworks system. It will be added to the employee’s gross earnings for tax purposes, but not added to their take-home pay.",
      },
      {
        value: "cash",
        label: "Cash",
        description:
          "Issue a payment that will add to your employee’s gross earnings for the purposes of tax reporting and increase their take-home pay.",
      },
    ],
    required: true,
    message: {
      text: "We can only process electronic payments. To record a cash payment, reach out to <strong>international-support@justworks.com</strong>",
    },
  },
  {
    label: "Pay date",
    name: "payPeriodId",
    type: COMPONENTLIST.SELECT,
    options: [{ value: "", label: "" }],
    required: true,
    disabled: false,
    disabledCheck: (data) => {
      const settings = data?.setUp.payload
        .paymentSettings as TaxableBenefitsPaymentSettings;
      const { formOfIncome } = settings;

      return (
        data?.setUp.type === BenefitsAndAllowancesType.BENEFITSANDALLOWANCES &&
        formOfIncome === "non-cash"
      );
    },
    renderCheck: (data) => {
      const settings = data?.setUp.payload
        .paymentSettings as TaxableBenefitsPaymentSettings;

      const { formOfIncome } = settings;
      return !!formOfIncome;
    },
    conditionalMessage: (data) => {
      const settings = data?.setUp.payload
        .paymentSettings as TaxableBenefitsPaymentSettings;
      const { formOfIncome } = settings;
      const message = {
        text: "Non-cash taxable benefits will be applied to the next pay period.",
      };

      return formOfIncome === "non-cash" ? message : null;
    },
  },
  {
    label: "Internal Notes",
    name: "notes",
    type: COMPONENTLIST.TEXTAREA,
    required: false,
    message: { text: "Only visible to admins" },
  },
];
