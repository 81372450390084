import {
  ArrayConfig,
  FieldTypes,
} from "pages/employer-of-record/components/AutoForm";
import { EorEmployeeProfile } from "types/employer-of-record";
import { ProfileField } from "./types";
import { ProfileFieldData, ProfileFieldName } from "./profileFields";

export function initialValues(
  fields: ProfileField<FieldTypes, ArrayConfig | undefined>[],
  profile: EorEmployeeProfile
): Partial<ProfileFieldData> {
  const values: Partial<ProfileFieldData> = {};

  for (const field of fields) {
    values[field.name as ProfileFieldName] = field.value(
      profile
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as unknown as any;
  }

  return values;
}
