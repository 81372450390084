import { ReactElement } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { EditProfile, Page404 } from "../index";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { CompensationHistory } from "./compensation-history";
import { subroutes } from "pages/employer-of-record/account-settings/EorEmployeeAccountSettings";
import { useGetMemberTypeQuery } from "types/generated/operations";
import { Spinner } from "@justworkshr/milo-core";
import { FeatureFlagIds } from "lib/launch-darkly/feature-flags-provider";
import BasicInfo from "./basic-info/basic-info";

//This is where the subroutes for account settings begin
const EDIT_PROFILE_PATH = "edit-profile";
const BASIC_INFO_PATH = "basic-information";
const COMPENSATION_HISTORY_PATH = "compensation-history";

// Determine which component to use for the /basic-information page based on the member type.
function getBasicInfoComponent(
  memberType: string | undefined,
  getFeatureFlag: (flagId: FeatureFlagIds) => string | number | boolean | object
) {
  const isEorEmployee = memberType?.includes("EOR");
  const isPeoEmployee = !isEorEmployee;
  const peoAccountSettingsEnabled = getFeatureFlag(
    "release-basic-information-page-cf"
  );

  if (isEorEmployee) {
    return {
      component: Outlet,
      subroutes: subroutes({ editable: true }),
    };
  }

  if (isPeoEmployee && peoAccountSettingsEnabled) {
    return {
      component: BasicInfo,
    };
  }

  return undefined;
}

export default function AccountSettings(): ReactElement {
  const { getFeatureFlag } = useGetFeatureFlag();
  const { data, loading } = useGetMemberTypeQuery();
  const [searchParams] = useSearchParams();

  const basicInfo = getBasicInfoComponent(
    data?.authenticatedMember.memberType,
    getFeatureFlag
  );

  const BasicInfoComponent = basicInfo?.component;

  if (loading) return <Spinner />;

  return (
    <Routes>
      {!!BasicInfoComponent && (
        // If someone navigates to `/account-settings`, redirect to the basic information subsection
        <Route
          path=""
          element={
            <Navigate
              replace
              to={`${BASIC_INFO_PATH}?${searchParams.toString()}`}
            />
          }
        />
      )}
      <Route path={EDIT_PROFILE_PATH} element={<EditProfile />} />
      {!!BasicInfoComponent && (
        <Route path={BASIC_INFO_PATH} element={<BasicInfoComponent />}>
          {basicInfo?.subroutes}
        </Route>
      )}
      {getFeatureFlag("release-compensation-history-page-cf") && (
        <Route
          path={COMPENSATION_HISTORY_PATH}
          element={<CompensationHistory />}
        />
      )}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
