import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";

export const portugalFields = {
  ptCc: field({
    type: FieldTypes.text,
    informationType: "cartao",
    name: "ptCc",
    label: "Number",
    scenarios: {
      viewProfile: countryIs("PT"),
    },
    value: personalInfo("ptCc", ""),
  }),
  ptCcIssDate: field({
    type: FieldTypes.date,
    informationType: "cartao",
    name: "ptCcIssDate",
    label: "Issue date",
    scenarios: {
      editProfile: countryIs("PT"),
      viewProfile: countryIs("PT"),
    },
    value: personalInfo("ptCcIssDate", ""),
  }),
  ptCcExpDate: field({
    type: FieldTypes.date,
    informationType: "cartao",
    name: "ptCcExpDate",
    label: "Expiration date",
    scenarios: {
      editProfile: countryIs("PT"),
      viewProfile: countryIs("PT"),
    },
    value: personalInfo("ptCcExpDate", ""),
  }),
  ptNiss: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "ptNiss",
    label: "Instituto Nacional do Seguro Social (NISS)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("PT"),
    },
    value: personalInfo("ptNiss", ""),
  }),
  ptNif: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "identifierNumber",
    label: "Número de Identificação Fiscal (NIF)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("PT"),
    },
    value: personalInfo("identifierNumber", ""),
  }),
};
