import { ReactElement } from "react";
import { NotificationMemberDetails } from "types";
import styles from "../alerts-notification/alerts-notification.module.css";
import { DisplayIcon } from "@justworkshr/milo-icons";

const { alertsNotification, hasAvatar, bankSetupIcon, bankSetupLink } = styles;

export default function ConnectCompanyBankNotification({
  message,
  url,
}: {
  message: string;
  url?: string;
  member?: NotificationMemberDetails;
}): ReactElement {
  return (
    <div className={`${alertsNotification} ${hasAvatar}`}>
      <DisplayIcon
        className={bankSetupIcon}
        iconName={"briefcase"}
        color={"blueGreen"}
      />
      <span>
        <a href={url} className={bankSetupLink}>
          {message}
        </a>
      </span>
    </div>
  );
}
