import { AlertMessage } from "pages/team-management/components/AlertMessage";
import {
  calculateBenefitsEndDate,
  formatDateAsString,
  getAnswer,
} from "../../SeparationReview.util";
import styles from "../../SeperationReviewPage.module.css";
import { MemberSeparationAnswer } from "pages/team-management/types";
import { BENEFITS_ALERT_COPY } from "./BenefitsAlert.constants";
import { DisplayIcon } from "@justworkshr/milo-icons";

const { subHeading } = styles;

interface DetailsSectionProps {
  answers: MemberSeparationAnswer[];
  memberName: string;
}

export const BenefitsAlert = ({ answers, memberName }: DetailsSectionProps) => {
  const terminationDateString = getAnswer(answers, "termination_date")
    ?.valueDate as string;
  const benefitsEndDate = calculateBenefitsEndDate(terminationDateString); // Calculate the benefits end date

  return (
    <AlertMessage
      data-testid="benefits-alert"
      iconComponent={<DisplayIcon iconName="circle-with-cross" size="small" />}
    >
      <div className={subHeading}>
        {BENEFITS_ALERT_COPY.benefits_alert_title}
      </div>
      <div>
        {memberName}'s separation is scheduled for {terminationDateString}.
        Their benefits will end on {formatDateAsString(benefitsEndDate)}. We
        will send you an email with next steps to help manage this process.
      </div>
    </AlertMessage>
  );
};
