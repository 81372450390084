import { Provider, ErrorBoundary } from "@rollbar/react";
import { Configuration, LogArgument, Dictionary } from "rollbar";

const buildEnv = import.meta.env.VITE_ENV;
const codeVersion = import.meta.env.VITE_CODE_VERSION;
const rollbarConfig: Configuration = {
  enabled: ["staging", "production"].indexOf(buildEnv) > -1,
  accessToken: "24b3a578163c4d11b4b6fef303c4d540",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: buildEnv,
    client: {
      javascript: {
        code_version: codeVersion,
        source_map_enabled: true,
      },
    },
  },
  ignoredMessages: [
    "An error occurred while fetching feature flags: Unauthorized request",
    "Uncaught Error: An error occurred while fetching feature flags: Unauthorized request",
  ],
  checkIgnore: (
    isUncaught: boolean,
    args: LogArgument[],
    payload: Dictionary
  ) => {
    // Check both the message and the exception description
    const errorMessage = (payload.body as { message?: string })?.message;
    const exceptionDesc = (
      payload.body as { trace?: { exception?: { description?: string } } }
    )?.trace?.exception?.description;
    const unauthorizedFeatureFlagError =
      "An error occurred while fetching feature flags: Unauthorized request";
    return (
      (errorMessage?.includes(unauthorizedFeatureFlagError) ||
        exceptionDesc?.includes(unauthorizedFeatureFlagError)) ??
      false
    );
  },
  /* eslint-disable @typescript-eslint/no-explicit-any */
  transform: (payload: { [key: string]: any }) => {
    if (payload.body.trace) {
      const frames = payload.body.trace.frames;
      if (frames && frames.length > 0) {
        const frame = frames[frames.length - 1];
        const directory = frame.filename;
        payload.body.trace.extra = payload.body.trace.extra || {};
        payload.body.trace.extra.path = directory;
      }
    }
  },
};

export default function RollbarProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}
