import { FC } from "react";
import useOnboardingForm from "../hooks/useOnboardingForm";
import ProfileInfoGlobal from "./form/global/ProfileInfoGlobal";
import CUSTOM_COUNTRY from "./countryMapper";

const STATE_KEY = "profile-info";

type CustomWorkCountry = keyof typeof CUSTOM_COUNTRY;

const isCustomCountry = (
  workCountry: string
): workCountry is CustomWorkCountry => workCountry in CUSTOM_COUNTRY;

const ProfileInfo: FC = () => {
  const {
    memberData: { workCountry },
  } = useOnboardingForm(STATE_KEY);

  if (workCountry && isCustomCountry(workCountry)) {
    const ProfileInfoCustomCountry = CUSTOM_COUNTRY[workCountry];
    return <ProfileInfoCustomCountry />;
  }

  return <ProfileInfoGlobal />;
};

export default ProfileInfo;
