import React, { ReactElement, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import {
  ACCOUNT_SETTINGS_PATH,
  AccountSettings,
  BASE_EXPENSES_PATH,
  BASE_FORM_8973_PATH,
  BASE_TEAM_MANAGEMENT_PATH,
  BENEFITS_PATH,
  BenefitsApp,
  COMPANY_SETTINGS_PATH,
  CompanySettings,
  EMPLOYER_OF_RECORD_PATH,
  EmployerOfRecord,
  ExpenseManagementApp,
  FIRMS_PATH,
  FirmsApp,
  INTEGRATION_MARKETPLACE_PATH,
  IntegrationMarketplace,
  InternationalContractorMemberDetail,
  INTERNATIONAL_CONTRACTOR_MEMBER_DETAIL_PATH,
  INTERNATIONAL_SOLUTIONS_PATH,
  InternationalSolutionsPage,
  Invitation,
  INVITATION_CENTER_PATH,
  INVITATION_PATH,
  InvitationCenter,
  LIFE_AND_LEAVE_ENROLLMENT_PATH,
  LifeAndLeaveEnrollment,
  MORE_SERVICES_PATH,
  MoreServices,
  Page404,
  PARTNER_DESCRIPTION_PAGE_PATH,
  PARTNER_SETUP_PAGE_PATH,
  PartnerDescriptionPage,
  PartnerSetupPage,
  PEOPLE_ANALYTICS_PATH,
  PeopleAnalytics,
  TeamManagementApp,
  WC_DOCUMENT_REQUEST_PATH,
  WCDocumentRequest,
  WC_CLAIMS_REQUEST_PATH,
  WCClaimsHome,
  PAY_TRANSACTIONS_PATH,
  PayTransactions,
  ROLLBAR_PAGE_PATH,
  RollbarPage,
  NSF_RESOLUTION_INSTRUCTIONS_PATH,
  NsfResolutionInstructions,
  TIME_TRACKING_LANDING_PATH,
  TimeTrackingLanding,
  FIRM_INVITATION_PATH,
  FirmInvitationApp,
  FORBIDDEN_ERROR_PATH,
  Page403,
  UNKNOWN_ERROR_PATH,
  Page520,
  VOUCHERS_PATH,
} from "pages";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { Form8973 } from "pages/form-8973";
import Paystubs from "../../pages/employer-of-record/paystubs/Paystubs";

export default function Viewport(): ReactElement {
  const { getFeatureFlag } = useGetFeatureFlag();

  return (
    <div className="milo--root">
      <Routes>
        <Route path={MORE_SERVICES_PATH} element={<MoreServices />} />
        <Route path={VOUCHERS_PATH} element={<Paystubs />} />
        <Route
          path={`${ACCOUNT_SETTINGS_PATH}/*`}
          element={<AccountSettings />}
        />
        <Route
          path={`${COMPANY_SETTINGS_PATH}/*`}
          element={<CompanySettings />}
        />
        {getFeatureFlag("release-peeps-pay-transaction-page") && (
          <Route
            path={`${PAY_TRANSACTIONS_PATH}/*`}
            element={<PayTransactions />}
          />
        )}
        <Route
          path={`${WC_DOCUMENT_REQUEST_PATH}/*`}
          element={<WCDocumentRequest />}
        />
        <Route
          path={INVITATION_CENTER_PATH}
          element={
            <Suspense fallback="Loading...">
              <InvitationCenter />
            </Suspense>
          }
        />
        {flagIsEnabled(FLAG_NAMES.INVITATION_PAGE_REWRITE) && (
          <Route path={INVITATION_PATH} element={<Invitation />} />
        )}
        <Route
          path={INTEGRATION_MARKETPLACE_PATH}
          element={<IntegrationMarketplace />}
        />
        <Route
          path={`${BASE_EXPENSES_PATH}/*`}
          element={<ExpenseManagementApp />}
        />
        <Route
          path={`${EMPLOYER_OF_RECORD_PATH}/*`}
          element={
            <Suspense fallback="Loading...">
              <EmployerOfRecord />
            </Suspense>
          }
        />
        <Route
          path={`${INTERNATIONAL_SOLUTIONS_PATH}/*`}
          element={
            <Suspense fallback="Loading...">
              <InternationalSolutionsPage />
            </Suspense>
          }
        />
        <Route
          path={`${PEOPLE_ANALYTICS_PATH}/*`}
          element={<PeopleAnalytics />}
        />
        {getFeatureFlag("release-wc-claims-request") && (
          <Route
            path={`${WC_CLAIMS_REQUEST_PATH}/*`}
            element={<WCClaimsHome />}
          />
        )}
        <Route
          path={PARTNER_DESCRIPTION_PAGE_PATH}
          element={<PartnerDescriptionPage />}
        />
        <Route path={PARTNER_SETUP_PAGE_PATH} element={<PartnerSetupPage />} />
        <Route path={`${FIRMS_PATH}/*`} element={<FirmsApp />} />
        <Route
          path={`${LIFE_AND_LEAVE_ENROLLMENT_PATH}/*`}
          element={<LifeAndLeaveEnrollment />}
        />
        <Route
          path={`${BASE_TEAM_MANAGEMENT_PATH}/*`}
          element={<TeamManagementApp />}
        />
        {flagIsEnabled(FLAG_NAMES.INTERNATIONAL_CONTRACTOR_DOCUMENTS) && (
          <Route
            path={`${INTERNATIONAL_CONTRACTOR_MEMBER_DETAIL_PATH}/*`}
            element={<InternationalContractorMemberDetail />}
          />
        )}
        <Route path={`${BENEFITS_PATH}/*`} element={<BenefitsApp />} />
        <Route path={`${ROLLBAR_PAGE_PATH}`} element={<RollbarPage />} />
        <Route
          path={`${NSF_RESOLUTION_INSTRUCTIONS_PATH}`}
          element={<NsfResolutionInstructions />}
        />
        {getFeatureFlag("release-form-8973") && (
          <Route path={`${BASE_FORM_8973_PATH}/*`} element={<Form8973 />} />
        )}
        {getFeatureFlag("release-ieor-time-tracking-landing") && (
          <Route
            path={TIME_TRACKING_LANDING_PATH}
            element={<TimeTrackingLanding />}
          />
        )}
        <Route
          path={`${FIRM_INVITATION_PATH}/*`}
          element={<FirmInvitationApp />}
        />
        <Route path={FORBIDDEN_ERROR_PATH} element={<Page403 />} />
        <Route path={UNKNOWN_ERROR_PATH} element={<Page520 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
}
