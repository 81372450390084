import { Accordion, AccordionPanel } from "@justworkshr/milo-core";
import styles from "./ResolutionInstructions.module.css";
import { toCurrency } from "pages/pay-transactions/util/toCurrency";

const { AccordionWrapper, WhatToDoNextWrapper, TableWrapper, Email } = styles;

interface ResolutionInstructionsProps {
  invoiceAmount: number;
  dueDate: string;
  invoiceId: string;
  payerIdAccountNumber?: string | null;
  payerIdRoutingNumber?: string | null;
}

export function ResolutionInstructions({
  invoiceAmount,
  dueDate,
  invoiceId,
  payerIdAccountNumber,
  payerIdRoutingNumber,
}: ResolutionInstructionsProps) {
  const bankName =
    payerIdAccountNumber && payerIdRoutingNumber
      ? "Citibank"
      : "JP Morgan Chase";
  const bankAddress =
    payerIdAccountNumber && payerIdRoutingNumber
      ? "100 Citibank Drive, San Antonio, TX 78245"
      : "270 Park Avenue, New York, NY 10172";
  const accountNumber =
    payerIdAccountNumber && payerIdRoutingNumber
      ? payerIdAccountNumber
      : "769853257";
  const routingNumber =
    payerIdAccountNumber && payerIdRoutingNumber
      ? payerIdRoutingNumber
      : "021000021";
  const internationalCode =
    payerIdAccountNumber && payerIdRoutingNumber ? "CITIUS33" : "BIC CHASUS33";

  return (
    <div className={AccordionWrapper}>
      <Accordion>
        <AccordionPanel title="What to do next?" isOpen>
          <div className={WhatToDoNextWrapper}>
            <div>1.</div>
            <div>
              Initiate a same-day wire or instant payment transfer to Justworks
              for the full invoice amount of{" "}
              <strong>{toCurrency(invoiceAmount, "USD", "never")}</strong> for
              <strong> invoice {invoiceId}</strong>. The funds must be received
              by{" "}
              <strong>
                1pm EST today,{" "}
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  timeZone: "UTC",
                }).format(new Date(dueDate))}
              </strong>{" "}
              or the disbursements will be canceled. Please note, we are not
              able to re-attempt processing the invoice or its intended payments
              at that time.
            </div>
          </div>
          <div className={TableWrapper}>
            Here are the details for the wire:
            <table>
              <tr>
                <th>Name on account</th>
                <td>
                  <p>Justworks Employment Group LLC</p>
                  <p>PO Box 7119</p>
                  <p>Church Street Station</p>
                  <p>New York, NY 10008-7119</p>
                  <p>+1888-534-1711</p>
                </td>
              </tr>
              <tr>
                <th>Bank</th>
                <td>{bankName}</td>
              </tr>
              <tr>
                <th>Bank address</th>
                <td>{bankAddress}</td>
              </tr>
              <tr>
                <th>Account number</th>
                <td>{accountNumber}</td>
              </tr>
              <tr>
                <th>Routing number</th>
                <td>{routingNumber}</td>
              </tr>
              <tr>
                <th>International code</th>
                <td>{internationalCode}</td>
              </tr>
            </table>
          </div>
          <div className={WhatToDoNextWrapper}>
            <div>2.</div>
            <div>
              Once the transfer is completed, send a screenshot of the same-day
              wire confirmation to{" "}
              <span className={Email}>support@justworks.com</span>, and include
              the invoice ID in the email.
            </div>
          </div>
        </AccordionPanel>
        <AccordionPanel title="What happens if my bank cannot meet the deadline?">
          If your bank is unable to guarantee the funds will land in the
          Justworks bank account by 1 pm EST via same-day wire or instance
          payment transfer, the disbursements will be canceled and you will need
          to plan to reschedule the payments.
        </AccordionPanel>
        <AccordionPanel title="Why is the deadline so important?">
          We genuinely care about your business's success and want to ensure a
          smooth payroll process for you and your employees. Late wage payments
          can pose compliance risks and potentially lead to penalties under
          state and federal laws. Your company is required to pay invoices on
          time per our customer service agreement (CSA) in order to continue
          your payroll and keep your account active on the platform. We are here
          to help you resolve this as soon as possible.
        </AccordionPanel>
      </Accordion>
    </div>
  );
}
