const GENDER_OPTIONS = [
  {
    value: "male",
    description: "Male",
  },
  {
    value: "female",
    description: "Female",
  },
];

const SHG_FUNDS_OPTIONS = [
  {
    value: "cdac",
    description: "Chinese Development Assistance Council (CDAC) Fund",
  },
  {
    value: "ecf",
    description: "Eurasian Community Fund (ECF)",
  },
  {
    value: "mbmf",
    description: "Mosque Building and Mendaki Fund (MBMF)",
  },
  {
    value: "sinda",
    description: "Singapore Indian Development Association (SINDA) Fund",
  },
];

const CITIZENSHIP_STATUS_OPTIONS = [
  {
    value: "permanent-resident",
    description: "Permanent Resident",
  },
  {
    value: "citizen",
    description: "Citizen",
  },
];

export { GENDER_OPTIONS, SHG_FUNDS_OPTIONS, CITIZENSHIP_STATUS_OPTIONS };
