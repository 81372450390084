import { Badge } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import { useGetAdminStatusQuery } from "types/generated/operations";
import CostCalculator from "./items/CostCalculator";
import styles from "./Tools.module.css";

const { title, container, badge } = styles;

function Tools() {
  const adminStatus = useGetAdminStatusQuery();
  const { t } = useTranslation();

  const isAdmin = adminStatus.data?.authenticatedMember?.adminStatus;

  if (!isAdmin) return null;

  return (
    <div className={container}>
      <div className={title}>
        <h2>{t("Tools")}</h2>
        <Badge role="new" className={badge}>
          {t(" New")}
        </Badge>
      </div>
      <CostCalculator />
    </div>
  );
}

export default Tools;
