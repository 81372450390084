import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { getDescription } from "pages/employer-of-record/utils";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";
import * as BRAZIL from "pages/employer-of-record/onboarding/profile-info/form/br/constants.br";

export const brazilFields = {
  brGender: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "brGender",
    label: "Gender",
    placeholder: "Select Gender",
    message:
      "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences.",
    options: BRAZIL.GENDER_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brGender", ""),
    format: (value) => getDescription(BRAZIL.GENDER_OPTIONS, value) ?? value,
  }),
  brCityOfBirth: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "brCityOfBirth",
    label: "City of birth",
    validations: REQUIRED,
    scenarios: {
      editProfile: countryIs("BR"),
      viewProfile: countryIs("BR"),
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brCityOfBirth", ""),
  }),
  brStateOfBirth: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "brStateOfBirth",
    label: "State of birth",
    placeholder: "Select State of Birth",
    validations: REQUIRED,
    options: BRAZIL.STATE_OPTIONS,
    scenarios: {
      editProfile: countryIs("BR"),
      viewProfile: countryIs("BR"),
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brStateOfBirth", ""),
    format: (value) => getDescription(BRAZIL.STATE_OPTIONS, value) ?? value,
  }),
  brEthnicity: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "brEthnicity",
    label: "Ethnicity",
    placeholder: "Select Ethnicity",
    options: BRAZIL.ETHNICITY_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      editProfile: countryIs("BR"),
      viewProfile: countryIs("BR"),
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brEthnicity", ""),
    format: (value) => getDescription(BRAZIL.ETHNICITY_OPTIONS, value) ?? value,
  }),
  brWorkPermitStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "brWorkPermitStatus",
    label: "Work permit status",
    placeholder: "Select Work Permit status",
    options: BRAZIL.WORK_PERMIT_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      editProfile: (profile, authenticatedMember) =>
        profile.employment?.workCountry === "BR" &&
        authenticatedMember.uuid !== profile.personalInfo?.memberUuid,
      viewProfile: countryIs("BR"),
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brWorkPermitStatus", ""),
    format: (value) =>
      getDescription(BRAZIL.WORK_PERMIT_OPTIONS, value) ?? value,
  }),
  brMaritalStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "brMaritalStatus",
    label: "Marital status",
    placeholder: "Select Marital status",
    validations: REQUIRED,
    options: BRAZIL.MARITAL_STATUS_OPTIONS,
    scenarios: {
      editProfile: countryIs("BR"),
      viewProfile: countryIs("BR"),
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brMaritalStatus", ""),
    format: (value) =>
      getDescription(BRAZIL.MARITAL_STATUS_OPTIONS, value) ?? value,
  }),
  brazilCpf: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "identifierNumber",
    label: "Cadastro de Pessoas Físicas (CPF)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("BR"),
    },
    value: personalInfo("identifierNumber", ""),
  }),
  brEducationalQualification: field({
    type: FieldTypes.text,
    informationType: "education",
    name: "brEducationalQualification",
    label: "Level of education",
    validations: REQUIRED,
    scenarios: {
      editProfile: (profile) => profile.employment?.workCountry === "BR",
      viewProfile: countryIs("BR"),
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brEducationalQualification", ""),
  }),
  brPersonalId: field({
    type: FieldTypes.text,
    informationType: "rg",
    name: "brPersonalId",
    label: "ID Number (RG)",
    validations: {
      ...REQUIRED,
      text: {
        matches: {
          value: BRAZIL.brPersonalIdRegEx,
          message: "Must be a valid Personal ID.",
        },
      },
    },
    scenarios: {
      viewProfile: countryIs("BR"),
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brPersonalId", ""),
  }),
  brRgStateOfIssue: field({
    type: FieldTypes.select,
    informationType: "rg",
    name: "brRgStateOfIssue",
    label: "RG state of issue",
    placeholder: "Select state of issue",
    options: BRAZIL.STATE_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("BR"),
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brRgStateOfIssue", ""),
  }),
  brRgIssuingBody: field({
    type: FieldTypes.text,
    informationType: "rg",
    name: "brRgIssuingBody",
    label: "RG issuing body",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("BR"),
      onboarding: countryIs("BR"),
    },
    value: personalInfo("brRgIssuingBody", ""),
  }),
};
