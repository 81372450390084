import { ReactElement, useState } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box, Button } from "@justworkshr/milo-core";
import {
  CF_ADD_EMPLOYEES_LINK,
  EOR_PRICE,
} from "pages/international-solutions/constants";
import ThankYouDialog from "../Dialog/ThankYouDialog";
import {
  useGetIsEmployerOfRecordEnabledQuery,
  useGetCompanyDisplayQuery,
} from "types/generated/operations";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import {
  sendCustomerIOEvent,
  CUSTOMER_IO_CTA_CLICK_EVENT,
} from "app/customer-io";

const EORPriceCard = (): ReactElement => {
  const { divWrapper, mdGap, mdHead, mdPadding, hr, smFont, smGap, xsPadding } =
    styles;

  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const handleThankYouDialog = () => {
    setShowThankYouDialog((prev) => !prev);
  };

  const { data: eorEnabledData, loading } =
    useGetIsEmployerOfRecordEnabledQuery();
  const isEmployerOfRecordEnabled =
    eorEnabledData?.authenticatedMember.company.companySetting?.value ===
      "true" && flagIsEnabled(FLAG_NAMES.EMPLOYER_OF_RECORD);

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();

  const triggerCustomerIOEvent = (ctaName: string, nextUrl: string) => {
    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_CTA_CLICK_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: {
          current_url: window.location.href,
          cta_name: ctaName,
          next_url: nextUrl,
        },
      },
      accessToken
    );
  };

  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="md"
    >
      <div className={`${divWrapper} ${mdGap}`}>
        <div className={`${divWrapper} ${xsPadding} ${smGap}`}>
          <div className={mdHead}>Company Cost </div>
        </div>
        <hr className={hr}></hr>
        <div className={`${divWrapper} ${mdPadding} ${smGap}`}>
          <div className={mdHead}>Pricing </div>
          <div className={smFont}>{EOR_PRICE}</div>
        </div>
        <hr className={hr}></hr>
        <div className={`${divWrapper} ${mdPadding} ${smGap}`}>
          <div className={mdHead}>Country Coverage </div>
          <div className={smFont}>
            Top Talent Markets include the UK, Canada, Mexico, Portugal,
            Netherlands, and many more.
          </div>
        </div>

        {isEmployerOfRecordEnabled ? (
          <Button
            data-heap-id="eor-add-employees-button"
            color="brand"
            type="button"
            size="md"
            as="a"
            href={CF_ADD_EMPLOYEES_LINK}
            onClick={() => {
              triggerCustomerIOEvent(
                "eor_eor_add_employee_button",
                CF_ADD_EMPLOYEES_LINK
              );
            }}
          >
            Get started
          </Button>
        ) : (
          <Button
            data-heap-id="no-eor-im-interested-button"
            color="brand"
            type="button"
            size="md"
            onClick={() => {
              triggerCustomerIOEvent(
                "eor_eor_im_interested_button",
                "thank-you-modal"
              );
              handleThankYouDialog();
            }}
            loading={loading}
          >
            Get started
          </Button>
        )}
      </div>

      {showThankYouDialog && (
        <ThankYouDialog
          isOpen={showThankYouDialog}
          onClose={handleThankYouDialog}
        />
      )}
    </Box>
  );
};

export default EORPriceCard;
